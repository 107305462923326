import styled from 'styled-components';

import { useAppSelector } from 'store';
import {
    Breadcrumbs,
    FormLabel,
    FormTextarea,
    InfoCard,
    StatusLoader
} from 'app/components';
import { PrivateLayout } from 'app/layouts';
import { useEffect, useMemo, useState } from 'react';
import { FormSwitch } from 'app/components/FormSwitch';
import { API } from 'api';
import { Loader } from 'app/components/Loader';
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../services/settingsService';
import { FormInput } from 'app/components/FormInput';
import { Button } from 'app/components/Button';
import toast, { Toaster } from 'react-hot-toast';


const Centered = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 398px;
    margin: 0 auto;
    min-height: 500px;

    h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.75px;
        text-align: center;
        color: var(--grey-9);        
    }

    .form-date-input {
        border-radius: 8px;
        padding: 12px 16px;
        width: 100%;
        border: none;
        background: var(--grey-2);
        color: var(--blue);
        font-size: 14px;
    }

    .out-of-office-description {
        color: var(--grey-8);
        font-size: 14px;
        line-height: 16px;
        margin-top: -24px;
    }

    @media (max-width:900px) and (min-width:0px) {
        width: auto !important;
    }
`;

const NotificationSetting = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: var(--grey-2);
    padding: 24px 24px;
    border-radius: 24px;
    font-family: 'Mont', sans-serif;
`

const NotificationTitle = styled.div`
    font-weight: bold;
    color: var(--grey-9);
    font-size: 16px;
`

const NotificationDescription = styled.div`
    margin-top: 4px;
    color: var(--grey-8);
    font-size: 14px;
    line-height: 16px;
    margin-right: 18px;
`


const Container = styled.div`
    
    margin-top: 24px;
`;

const Line = styled.div`
    height: 2px;
    width: 100%;
    background: var(--grey-3);
    margin-bottom: -20px;
`

interface NotificationType {
    [prop: string]: NotificationSetting
}

interface OOOInfo {
    active: boolean;
    message: string | null;
    period_start: string | null;
    period_end: string | null;
}

export function NotificationsPage() {

    const {
        profile: {
            representative,
            brand,
        },
        global: { settings },
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );    

    const [notificationsData, setNotificationsData] = useState<any>({
        newApplications: false,
        newMessages: true,
        newMatches: true,
        unreadMessages: true,
        allGiftNotifications: true
    })

    const [loading, setLoading] = useState<boolean>(true);
    const [showMessage, setShowMessage] = useState<boolean>(false)
    const [messageContent, setMessageContent] = useState<string>('')
    const [messageStatus, setMessageStatus] = useState<string>('')
    const [outOfOfficeMessage, setOutOfOfficeMessage] = useState<string>('')
    const [outOfOfficeStart, setOutOfOfficeStart] = useState<string>('')
    const [outOfOfficeEnd, setOutOfOfficeEnd] = useState<string>('')
    const [outOfOfficeStatus, setOutOfOfficeStatus] = useState<boolean>(false)
    const [dateError, setDateError] = useState<string>('')

    useEffect(() => {
        if (!representative?.uid) {
            return
        }

        API.Profile.getNotificationSettings(representative.uid).then((res: any) => {
            const valueMap: any = {
                tx_influencer_gift_waved: 'newApplications',
                tx_brand_received_message: 'newMessages',
                tx_brand_unread_messages: 'unreadMessages',
                tx_brand_unread_messages_weekly: 'unreadMessagesWeekly',
                tx_brand_new_matches: 'newMatches',
                tx_brand_new_matches_weekly: 'newMatchesWeekly',
                all_gift_notifications: 'allGiftNotifications',
                tx_influencer_has_reviewed_brand: 'creatorReviews',
                tx_influencer_withdrawn_from_gifting: 'creatorWithdraws'
            }

            const data = {...notificationsData}

            for (let i = 0; i < res.length; i++) {
                data[valueMap[res[i].notification_type]] = !!res[i].value
            }

            setNotificationsData(data)
            setLoading(false);
        }).catch((error: any) => {
            console.error(error);
        });
    }, [])

    const handleToggleChange = (name: string) => {

        if (!representative?.uid) {
            return
        }

        setShowMessage(false)


        const data = {...notificationsData};
        data[name] = !data[name]

        let frequency;

        if (name === 'newMatchesWeekly') {
            data.newMatches = false
            frequency = 'WEEKLY'
        } else if (name === 'newMatches') {
            data.newMatchesWeekly = false
        }

        if (name === 'unreadMessagesWeekly') {
            data.unreadMessages = false
            frequency = 'WEEKLY'
        } else if (name === 'unreadMessages') {
            data.unreadMessagesWeekly = false
        }

        const valueMap: NotificationType = {
            newApplications: {notification_type: 'tx_influencer_gift_waved', value: data.newApplications},
            newMessages: {notification_type: 'tx_brand_received_message', value: data.newMessages},
            newMatches: {notification_type: 'tx_brand_new_matches', value: data.newMatches},
            newMatchesWeekly: {notification_type: 'tx_brand_new_matches_weekly', value: data.newMatchesWeekly},
            unreadMessages: {notification_type: 'tx_brand_unread_messages', value: data.unreadMessages},
            unreadMessagesWeekly: {notification_type: 'tx_brand_unread_messages_weekly', value: data.unreadMessagesWeekly},
            allGiftNotifications: {notification_type: 'all_gift_notifications', value: data.allGiftNotifications},
            creatorReviews: {notification_type: 'tx_influencer_has_reviewed_brand', value: data.creatorReviews},
            creatorWithdraws: {notification_type: 'tx_influencer_withdrawn_from_gifting', value: data.creatorWithdraws},
        }

        API.Profile.updateNotificationSetting(representative.uid, valueMap[name], frequency || undefined).then(() => {

            setMessageStatus('success')
            setMessageContent('Success!')
            setShowMessage(true)

            // hide notification after 3 seconds
            setTimeout(() => {
                setShowMessage(false)
            }, 3000)
        }).catch((error: any) => {
            setMessageStatus('error')
            setMessageContent(error?.message as string || 'Failed to update preference')
            setShowMessage(true)
        });

        setNotificationsData(data)
    }

    const handleSetOOOInfo = () => {
        if (!brand) {
            return;
        }

        if (!outOfOfficeStart || !outOfOfficeEnd) {
            setDateError('Please select a start and end date');
            return;
        }

        if (!validateDates(outOfOfficeStart, outOfOfficeEnd)) {
            return;
        }

        toast.promise(
            API.Profile.setOOOInfo(brand, {
                message: outOfOfficeMessage, 
                period_start: outOfOfficeStart, 
                period_end: outOfOfficeEnd, 
                active: outOfOfficeStatus
            }).catch((error: any) => {
                console.error(error)
                throw error
            }),
            {
                loading: 'Updating out of office settings...',
                success: 'Your out of office settings have been updated!',
                error: 'Failed to update out of office settings',
            },
            {
                style: {
                    textTransform: 'capitalize',
                    background: '#fff',
                    color: '#262161',
                },
                position: 'bottom-center',
                duration: 3000,
            }
        ).catch((error: any) => {
            console.error(error)
        })
    }

    const handleToggleOOO = () => {
        setOutOfOfficeStatus(!outOfOfficeStatus)
    }

    useEffect(() => {
        analyticsEvents.settingsNotificationsOpened();
    }, []);    

    const validateDates = (start: string, end: string) => {
        if (!start || !end) return true;
        
        const startDate = new Date(start);
        const endDate = new Date(end);
        
        if (startDate > endDate) {
            setDateError('Start date cannot be after end date');
            return false;
        }
        
        setDateError('');
        return true;
    }

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newStart = e.target.value;
        setOutOfOfficeStart(newStart);
        validateDates(newStart, outOfOfficeEnd);
    }

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEnd = e.target.value;
        setOutOfOfficeEnd(newEnd);
        validateDates(outOfOfficeStart, newEnd);
    }

    useEffect(() => {
        if (!brand) {
            return
        }

        API.Profile.getOOOInfo(brand).then((res: OOOInfo) => {
            setOutOfOfficeStatus(res.active)
            setOutOfOfficeMessage(res.message ?? '')
            setOutOfOfficeStart(res.period_start?.split(' ')[0] ?? '')
            setOutOfOfficeEnd(res.period_end?.split(' ')[0] ?? '')
        }).catch((error: any) => {
            console.error('Error getting out of office info', error)
        })
    }, [brand])

    return (
        <PrivateLayout>
            <Container data-testid={'notifications-page'}>
                <Breadcrumbs
                    items={[
                        { id: 'dashboard', name: 'Dashboard', route: '/' },
                        { id: 'notifications', name: 'Notifications' }
                    ]}
                />

                <Toaster />

                <InfoCard>
                    <Body>
                        {!loading ? (
                            <>
                                {/* <h1 style={{marginBottom: '-5px', marginTop: 0}}>Daily Summaries</h1> */}

                                <h1 style={{marginBottom: '-5px', marginTop: 0, textAlign: 'left'}}>I want to be alerted about new applicants:</h1>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>As they are sent</NotificationTitle>
                                        <NotificationDescription>
                                            Receive an email every time a new Creator applies for your Gift.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="newApplications" label="" onChange={() => {handleToggleChange('newApplications')}} checked={notificationsData.newApplications} />
                                </NotificationSetting>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Once per day
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive a daily summary email notifying you of any new applications from Creators.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="newMatches" label="" onChange={() => handleToggleChange('newMatches')} checked={notificationsData.newMatches} />
                                </NotificationSetting>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Once per week
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive a weekly summary email notifying you of any new applications from Creators.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="newMatches" label="" onChange={() => handleToggleChange('newMatchesWeekly')} checked={notificationsData.newMatchesWeekly} />
                                </NotificationSetting>



                                <Line />

                                <h1 style={{marginBottom: '-5px', textAlign: 'left'}}>I want to be alerted about new messages:</h1>


                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            As they are sent
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive an email every time a Creator messages you.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="newMessages" label="" onChange={() => {handleToggleChange('newMessages')}} checked={notificationsData.newMessages} />
                                </NotificationSetting>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Once per day
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive a daily summary email notifying you of any unread messages from Creators.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="unreadMessages" label="" onChange={() => {handleToggleChange('unreadMessages')}} checked={notificationsData.unreadMessages} />
                                </NotificationSetting>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Once per week
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive a weekly summary email notifying you of any unread messages from Creators.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="unreadMessages" label="" onChange={() => {handleToggleChange('unreadMessagesWeekly')}} checked={notificationsData.unreadMessagesWeekly} />
                                </NotificationSetting>

                                <Line />

                                <h1 style={{marginBottom: '-5px', textAlign: 'left'}}>I want to receive all notifications for gifts posted by my brand:</h1>


                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Gifts posted by others in my organization
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive emails for gifts not posted by you.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="allGiftNotifications" label="" onChange={() => {handleToggleChange('allGiftNotifications')}} checked={notificationsData.allGiftNotifications} />
                                </NotificationSetting>

                                <Line />

                                <h1 style={{marginBottom: '-5px', textAlign: 'left'}}>
                                    Miscellaneous Notifications
                                </h1>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Every time a creator I've accepted withdraws from gifting
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive emails every time a creator you've accepted for a gift decides to opt out.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="creatorWithdraws" label="" onChange={() => {handleToggleChange('creatorWithdraws')}} checked={notificationsData.creatorWithdraws} />
                                </NotificationSetting>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Every time a creator reviews my brand
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive emails every time a creator leaves a review for your brand.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="creatorReviews" label="" onChange={() => {handleToggleChange('creatorReviews')}} checked={notificationsData.creatorReviews} />
                                </NotificationSetting>



                            </>
                        ) : (
                            <Centered>
                                <Loader status="spin" />
                            </Centered>
                        )}

                    </Body>
                </InfoCard>



                <InfoCard>
                    <Body>
                        <h1>Out of Office</h1>

                        <p className='out-of-office-description'>Please specify your out-of-office dates so that we can inform creators when they send you a message.</p>

                        <FormLabel>Start Date</FormLabel>
                        <input
                            className="form-date-input"
                            type="date"
                            name="outOfOfficeStart"
                            value={outOfOfficeStart}
                            onChange={handleStartDateChange}
                            style={{ borderColor: dateError ? 'var(--error)' : undefined }}
                        />

                        <FormLabel>End Date</FormLabel>
                        <input
                            className="form-date-input"
                            type="date"
                            name="outOfOfficeEnd"
                            value={outOfOfficeEnd}
                            onChange={handleEndDateChange}
                            style={{ borderColor: dateError ? 'var(--error)' : undefined }}
                        />

                        {dateError && (
                            <div style={{
                                color: 'var(--error-active)',
                                fontSize: '14px',
                                marginTop: '4px'
                            }}>
                                {dateError}
                            </div>
                        )}

                        <FormSwitch name="outOfOffice" label="Out of Office Status" onChange={handleToggleOOO} checked={outOfOfficeStatus} />

                        <Button onClick={() => {handleSetOOOInfo()}}>Update</Button>
                    </Body>
                </InfoCard>
            </Container>

            <StatusLoader
                show={showMessage}
                status={messageStatus}
                title={'Notification Update'}
                message={messageContent}
            />

        </PrivateLayout>
    );
}
