import axios from './axios';
import subscriptionService  from "../services/subscriptionService";
import {API} from "./index";

const cancelBrandSubscription = (id: string) => {
    const url = `/producer/brands/${id}/subscription`;

    return new Promise<BrandSubscription>((resolve, reject) => {
        axios.delete(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as BrandSubscription);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const pauseBrandSubscription = (id: string) => {
    const url = `/producer/brands/${id}/subscription/pause`;

    return new Promise<BrandSubscription>((resolve, reject) => {
        axios.put(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as BrandSubscription);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const resumeBrandSubscription = (id: string) => {
    const url = `/producer/brands/${id}/subscription/resume`;

    return new Promise<BrandSubscription>((resolve, reject) => {
        axios.put(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as BrandSubscription);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}



const createBrandProfile = (
    representativeId: string,
    data: Omit<BrandForm, 'tags'> & {
        tags: (string | number)[];
    }
) => {
    const url = `/producer/representative/${representativeId}/brands`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('website', data.website);
    formData.append('brand_type', data.brand_type as string);

    if (data.affiliate_code) {
        formData.append('affiliate_code', data.affiliate_code as string);
    }

    data.tags.forEach((tag) => {
        formData.append('tags[]', tag.toString());
    });

    if (data.logo) {
        formData.append('logo', data.logo);
    }

    const freeTrial = localStorage.getItem('freetrial');
    if (freeTrial) {
        formData.append('features[]', 'subscriptions.trials');
        console.log('adding the trial to create brand');
    }

    return new Promise<BrandProfile>((resolve, reject) => {
        axios.post(url, formData, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as BrandProfile);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const createBrandProfileBilling = (
    id: string,
    data: BillingForm
) => {
    const url = `/producer/brands/${id}/billing`;

    return new Promise<number>((resolve, reject) => {
        axios.post(url, data)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const createBrandProfileSubscription = (
    id: string,
    data: SubscriptionForm
) => {
    const url = `/producer/brands/${id}/subscription`;

    data.proration_date = new Date().toISOString();

    return new Promise<BrandSubscription>((resolve, reject) => {
        axios.post(url, data)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as BrandSubscription);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => {
                reject(err)
            });
    });
}



const previewSubscription = (brand: BrandProfile, code: string, coupon?: string): Promise<SubscriptionPreviewResponse | void> => {

    const isNewSubscriptionRequired = subscriptionService.isNewSubscriptionRequired(brand.subscriptionv2 as BrandSubscription);

    if (isNewSubscriptionRequired && !coupon) {
        return new Promise((resolve) => {
            resolve()
        })
    }

    const url = `/producer/brands/${brand.uid}/subscription/preview/${isNewSubscriptionRequired ? 'new' : 'upgrade'}`;

    const data: PreviewSubscriptionBody = {
        proration_date: new Date().toISOString(), code: code
    }

    if (coupon) {
        data.coupon = coupon
    }

    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as SubscriptionPreviewResponse);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => {
                reject(err)
            });
    });
}

const applyCancelCouponToSubscription = (brand: BrandProfile): Promise<SubscriptionPreviewResponse | void> => {

    const url = `/producer/brands/${brand.uid}/subscription/coupon`;

    const coupon = ['localhost', 'staging'].includes(window.location.hostname) ? 'HERMCOUNT' : 'g6kc9tm';

    return new Promise((resolve, reject) => {
        axios.put(url, {coupon: coupon })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as SubscriptionPreviewResponse);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => {
                reject(err)
            });
    });
}


const getBrandProfile = (id: string) => {
    const url = `/producer/brands/${id}`;

    return new Promise<BrandProfile>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as BrandProfile);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getBrandTeam = (id: string) => {
    const url = `/producer/representative/${id}/team`;

    return new Promise<any>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    const accepted = response.data.filter((member: any) => member.invitation_status === 'ACCEPTED');
                    const pending = response.data.filter((member: any) => member.invitation_status === 'PENDING');
                    resolve([...accepted, ...pending])
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getBrandTeamInvite = (email: string, id: string) => {
    const url = `/producer/team/invite?email=${email}&brand=${id}`;

    return new Promise<BrandInvite>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as BrandInvite);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const sendBrandTeamInvite = (email: string, id: string) => {
    const url = `/producer/representative/${id}/team/invite`;

    return new Promise<boolean>((resolve, reject) => {
        axios.post(url, { emails: email }).then((response) => {
            if (response.status === 200) {
                resolve(response.data as boolean);
            } else {
                throw new Error('An error occurred. Please try again');
            }
        }).catch((err) => reject(err));
    })
}

const removeBrandTeamInvite = (email: string, id: string) => {
    const url = `/producer/representative/${id}/team/remove`;

    return new Promise<boolean>((resolve, reject) => {
        axios.post(url, { email: email }).then((response) => {
            if (response.status === 200) {
                resolve(response.data as boolean);
            } else {
                throw new Error('An error occurred. Please try again');
            }
        }).catch((err) => reject(err));
    })
}

const revokeBrandTeamInvite = (email: string, id: string) => {
    const url = `/producer/representative/${id}/team/invite/cancel`;

    return new Promise<boolean>((resolve, reject) => {
        axios.post(url, { email: email }).then((response) => {
            if (response.status === 200) {
                resolve(response.data as boolean);
            } else {
                throw new Error('An error occurred. Please try again');
            }
        }).catch((err) => reject(err));
    })
}


const resendBrandTeamInvite = (email: string, id: string) => {
    const url = `/producer/representative/${id}/team/resend`;

    return new Promise<boolean>((resolve, reject) => {
        axios.post(url, { email: email }).then((response) => {
            if (response.status === 200) {
                resolve(response.data as boolean);
            } else {
                throw new Error('An error occurred. Please try again');
            }
        }).catch((err) => reject(err));
    })
}


const acceptBrandInvite = (email: string) => {
    const url = `/producer/team/accept`;

    return new Promise<boolean>((resolve, reject) => {
        axios.post(url, { email: email }).then((response) => {
            if (response.status === 200) {
                resolve(response.data as boolean);
            } else {
                throw new Error('An error occurred. Please try again');
            }
        }).catch((err) => reject(err));
    })
}

const getBrandProfileBilling = (id: string) => {
    const url = `/producer/brands/${id}/billing`;

    return new Promise<SubscriptionBilling>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as SubscriptionBilling);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getInvoices = (id: string) => {
    const url = `/producer/brands/${id}/invoices`;

    return new Promise<Invoice[]>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Invoice[]);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getBrandReviews = (id: string) => {
    const url = `/producer/reviews/brands/${id}`;

    return new Promise<Review[]>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data as Review[]);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getNotifications = (id: string) => {
    const url = `/producer/brands/${id}/notifications`;

    return new Promise<PaginatedResponse<AppNotification>>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as PaginatedResponse<AppNotification>);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getProfiles = (
    representativeId: string,
    brandId: string
) => {
    return Promise.all([
        getRepresentativeProfile(representativeId),
        getBrandProfile(brandId)
    ]);
}

const getRepresentativeProfile = (id: string) => {
    const url = `/producer/representative/${id}`;

    return new Promise<RepresentativeProfile>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as RepresentativeProfile);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getSubscription = (id: string) => {
    const url = `/producer/brands/${id}/subscription`;

    return new Promise<string>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as string);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setBrandProfile = (
    id: string,
    data: Omit<BrandForm, 'tags'> & {
        tags: (string | number)[];
    }
) => {
    const url = `/producer/brands/${id}`;

    return new Promise<number>((resolve, reject) => {
        axios.put(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setBrandProfileBilling = (
    id: string,
    data: BillingForm
) => {
    const url = `/producer/brands/${id}/billing`;

    return new Promise<number>((resolve, reject) => {
        axios.post(url, data)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setBrandProfileLogo = (id: string, data: File) => {
    const url = `/producer/brands/${id}/logo`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('logo', data);

    return new Promise<{ logo: string }>((resolve, reject) => {
        axios.post(url, formData, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as { logo: string });
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setBrandProfileSocial = (
    id: string,
    data: SocialProfileForm
) => {
    const url = `/producer/brands/${id}/social`;

    return new Promise<number>((resolve, reject) => {
        axios.put(url, data)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const sendVerificationEmail = (id: string): Promise<void> => {
    const url = `/producer/representative/${id}/email/verify`;
    return new Promise<void>((resolve, reject) => {
        axios.post(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setSeenWaveHints = (id: string): Promise<void> => {
    const url = `/producer/representative/${id}/hints/wave-hints`;
    return new Promise<void>((resolve, reject) => {
        axios.post(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setBrandProfileSubscription = (
    id: string,
    data: SubscriptionForm
) => {
    const url = `/producer/brands/${id}/subscription`;

    data.proration_date = new Date().toISOString();

    return new Promise<BrandSubscription>((resolve, reject) => {
        axios.put(url, data)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as BrandSubscription);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setRepresentativeProfile = (
    id: string,
    data: RepresentativeForm
) => {
    const url = `/producer/representative/${id}`;

    if (!data.referral_message || !data.referral_message?.length) {
        data.referral_message = 'none';
    }

    return new Promise<number>((resolve, reject) => {
        axios.put(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const testVerificationCode = (code: string, email: string, repId: string) => {
    const url = `producer/email/verify/token`;

    return new Promise((resolve, reject) => {
        axios.post(url, JSON.stringify({token: code, email})).then((response) => {
            if (response.status === 200) {
                resolve(response.data)
            } else {
                throw new Error('An error occurred. Please try again');
            }
        }).catch((err) => reject(err));
    })
}

const setNotificationRead = (
    brandId: string,
    notificationId: string
) => {
    const url = `/producer/brands/${brandId}/notifications/${notificationId}/mark-as-read`;

    return new Promise<number>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setModalHistory = (brandId: string, modalName: string) => {
    const url = `/producer/brands/${brandId}/modal/history`;
    return new Promise<number>((resolve, reject) => {
        axios.post(url, { modal_name: modalName })
            .then((response) => {
                resolve(response.data as number);
            })
            .catch((err) => reject(err));
    });
}

const setRepresentativeProfileImage = (id: string, data: File) => {
    const url = `/producer/representative/${id}/headshot`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('headshot_image', data);

    return new Promise<{ headshot_image: string }>((resolve, reject) => {
        axios.post(url, formData, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as { headshot_image: string });
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const updateNotificationSetting = (id: string, setting: NotificationSetting, frequency?: string) => {
    const url = `/producer/representative/${id}/settings/notifications`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('notification_type', setting.notification_type);
    formData.append('value', setting.value ? '1' : '0');
    if (frequency) {
        formData.append('frequency', frequency);
    }

    return new Promise<void>((resolve, reject) => {
        axios.post(url, formData, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getNotificationSettings = (id: string) => {
    const url = `/producer/representative/${id}/settings/notifications`;
    return new Promise<NotificationSetting[]>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as NotificationSetting[]);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const createOrUpdateSubscription = async (brand: BrandProfile, form: SubscriptionForm): Promise<StripePaymentIntentResponse> => {
    if (subscriptionService.isNewSubscriptionRequired(brand.subscriptionv2 as BrandSubscription)) {
        return await API.Profile.createBrandProfileSubscription(brand.uid, form);
    } else {
        return await API.Profile.setBrandProfileSubscription(brand.uid, form);
    }
};

const checkEmailValidity = (email: string) => {
    const url = `/producer/email/validate/${email}`;
    return new Promise<number>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                resolve(response.data as number);
            })
            .catch((err) => reject(err));
    });
}

const getOOOInfo = (brand: BrandProfile) => {
   // get service/producer/brands/{brand_uid}/notifications/outOfOffice  Response: {id, brand_id, period_start, period_end, status, message}

   const url = `/producer/brands/${brand.uid}/out-of-office`;

   return new Promise<OOOInfo>((resolve, reject) => {
    axios.get(url)
        .then((response) => {
            resolve(response.data as OOOInfo);
        })
        .catch((err) => reject(err));
   })
}

const setOOOInfo = (brand: BrandProfile, info: OOOInfo) => {
    const url = `/producer/brands/${brand.uid}/out-of-office`;
    return new Promise<number>((resolve, reject) => {
        axios.post(url, info)
            .then((response) => {
                resolve(response.data as number);
            })
            .catch((err) => reject(err));
    });
}

export const profileAPI = {
    cancelBrandSubscription,
    createBrandProfile,
    createBrandProfileBilling,
    pauseBrandSubscription,
    createBrandProfileSubscription,
    createOrUpdateSubscription,
    getBrandProfile,
    getBrandProfileBilling,
    getInvoices,
    getNotifications,
    getProfiles,
    getRepresentativeProfile,
    getSubscription,
    getBrandTeam,
    getBrandTeamInvite,
    revokeBrandTeamInvite,
    sendBrandTeamInvite,
    removeBrandTeamInvite,
    resendBrandTeamInvite,
    previewSubscription,
    setBrandProfile,
    setBrandProfileBilling,
    setBrandProfileLogo,
    setBrandProfileSocial,
    setBrandProfileSubscription,
    setRepresentativeProfile,
    getBrandReviews,
    setNotificationRead,
    setRepresentativeProfileImage,
    updateNotificationSetting,
    resumeBrandSubscription,
    getNotificationSettings,
    sendVerificationEmail,
    setSeenWaveHints,
    applyCancelCouponToSubscription,
    acceptBrandInvite,
    testVerificationCode,
    setModalHistory,
    checkEmailValidity,
    getOOOInfo,
    setOOOInfo
};
