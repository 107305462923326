import settingsService, {ClientSettings} from "./settingsService";
import {useAppSelector} from "../store";

export enum PlanType {
    STARTER = 'Starter',
    GROW = 'Grow',
    PRO = 'Pro',
}

export interface Plan {
    type: PlanType,
    name: string,
    shortName: string,
    description: string,
}

export interface Page {
    showBackBtn: boolean,
    twoColumns: boolean,
    fullSized: boolean,
    rightSideImg: string,
    titleText: string,
    titleSubtext: string,
    showFilters: boolean,
    onNext: (() => void) | null,
    onBack: (() => void) | null,
}

export interface FeaturesPage extends Page {
    planType: PlanType,
    featureDescriptionText: string[],
    campaignFeatureText: string[]|string,
    giftCreatorsFeatureText: string[]|string,
    matchCreatorsFeatureText: string[]|string,
    captureStoriesFeaturesText: string[]|string,
    completedContentStatsText: string[]|string,
    nextButtonText: string
}

export interface ThreePlanPage extends Page {
    threePlanPage: boolean,
    featureDescriptionText: string[]|string,
    nextButtonText: string,
    trialDays: number|null,
    campaignFeatureText: string[]|string,
    giftCreatorsFeatureText: string[]|string,
    matchCreatorsFeatureText: string[]|string,
    captureStoriesFeaturesText: string[]|string,
    completedContentStatsText: string[]|string,
}

export interface FullSizedPaymentPage extends Page {
    trialDays: number|null,
    featureDescriptionText: string[],
    nextButtonText: string,
    campaignFeatureText: string[]|string,
    giftCreatorsFeatureText: string[]|string,
    matchCreatorsFeatureText: string[]|string,
    captureStoriesFeaturesText: string[]|string,
    completedContentStatsText: string[]|string,
    paymentPage: true
}

export interface ChoosePlanPage extends Page {
    featureDescriptionText: string[]|string,
    nextButtonText: string,
    trialDays: number|null,
    campaignFeatureText: string[]|string,
    giftCreatorsFeatureText: string[]|string,
    matchCreatorsFeatureText: string[]|string,
    captureStoriesFeaturesText: string[]|string,
    completedContentStatsText: string[]|string,
    choosePlanPage: true
}

export interface PaymentPage extends Page {
    trialDays: number|null,
    featureDescriptionText: string[],
    nextButtonText: string,
    campaignFeatureText: string[],
    giftCreatorsFeatureText: string[],
    completedContentStatsText: string[]|string,
    matchCreatorsFeatureText: string[],
    captureStoriesFeaturesText: string[],
    paymentPage: true
}

export interface SuccessPage extends Page {
    twoColumns: false,
    titleText: string,
    titleSubtext: string,
    nextButtonText: string,
    confetti: boolean
}

export enum SubscriptionFlowPageType {
    EXIT,
    FEATURES,
    THREE_PLAN_PAGE,
    FULL_SIZED_PAYMENT_PAGE,
    CHOOSE_PLAN,
    PAYMENT,
    SUCCESS
}

class SubscriptionService {

    /**
     * Used to decide if a subscription should be created or updated
     * See https://stripe.com/docs/billing/subscriptions/overview#subscription-statuses
     * @param brandSubscription
     */
    isNewSubscriptionRequired(brandSubscription?: BrandSubscription): boolean {
        if (!brandSubscription || brandSubscription.canceled_at || brandSubscription.deleted_at) {
            return true;
        }
        return ['incomplete', 'incomplete_expired', 'canceled', 'failed'].includes(brandSubscription.status);
    }

    isSubscribed(brandSubscription?: BrandSubscription): boolean {
        return !(!brandSubscription || !brandSubscription.status || ['incomplete', 'incomplete_expired', 'unpaid', 'past_due'].includes(brandSubscription.status));
    }

    isTrialing(brandSubscription?: BrandSubscription): boolean {
        if (!brandSubscription || !brandSubscription.status || ['trialing'].includes(brandSubscription.status)) {
            return false
        }

        return false
    }

    planDefinitions(): Plan[] {
        return [
            {
                name: '#gifted Basic',
                shortName: 'Starter',
                type: PlanType.STARTER,
                description: 'For up and coming brands experimenting with the power of creators marketing.'
            },
            {
                name: '#gifted Grow',
                shortName: 'Grow',
                type: PlanType.GROW,
                description: 'For brands & agencies looking to use creators at scale.'
            },
            {
                name: '#gifted Pro',
                shortName: 'Pro',
                type: PlanType.PRO,
                description: 'For brands and agencies hungering for fresh user generated content.'
            }
        ]
    }

    findUpgradePlan(type: PlanType): PlanType|null {
        switch (type) {
            case PlanType.STARTER:
                return PlanType.GROW;
            case PlanType.GROW:
                return PlanType.PRO;
        }
        return null;
    }

    findPlanDefinition(type: PlanType): Plan {
        for (const plan of this.planDefinitions()) {
            if (plan.type === type) {
                return plan;
            }
        }
        throw new Error('no ' + type + ' plan found');
    }

    findPlanTypeForSubscription(subscription: BrandSubscription) {
        const type = (this.features(subscription.subscription).get('plan.type') as string).toUpperCase();

        switch (type) {
            case 'STARTER':
                return PlanType.STARTER;
            case 'GROW':
                return PlanType.GROW;
            case 'PRO':
                return PlanType.PRO;
            default:
                throw new Error('no type found for subscription ' + subscription.code);
        }
    }

    findSubscription(plans: Subscription[], type: PlanType, frequency: SubscriptionFrequency) {
        plans = plans.filter(value => value.frequency === frequency);
        if (plans.length === 0) {
            throw new Error('no ' + type + ' plan found');
        }
        for (const plan of plans) {
            if (this.isSubscriptionType(plan, type)) {
                return plan;
            }
        }
        throw new Error('no ' + type + ' plan found');
    }

    isSubscriptionType(subscription: Subscription, type: PlanType) {
        return this.features(subscription).get('plan.type') === type.toLowerCase();
    }

    features(plan: Subscription) {
        const map = new Map();
        plan.features.forEach((feature: any) => {
            map.set(feature.name, feature.value);
        });
        return map;
    }

    featuresPage(brand: BrandProfile, availableSubscriptions: Subscription[], type: PlanType, onNext: () => void, onBack: () => void): FeaturesPage {
        const planDefinition = this.findPlanDefinition(type);
        const subscription = this.findSubscription(availableSubscriptions, type, 'YEAR');
        const features = this.features(subscription);
        const trialAvailable = this.isTrialAvailable(brand);
        return {
            fullSized: false,
            campaignFeatureText: ['Post ', `${features.get('gifts.create.limit') || 'unlimited'} campaigns`, ' per month'],
            captureStoriesFeaturesText: [features.get('content.stories.enabled') ?  'Capture Instagram, TikTok, and YouTube posts plus Instagram stories' : 'Capture Instagram, TikTok, and YouTube posts'],
            giftCreatorsFeatureText: ['Gift up to ', `${features.get('influencers.accept.limit') || 'unlimited'} creators`, ' per campaign'],
            nextButtonText: 'Choose a plan',
            featureDescriptionText: [planDefinition.description],
            matchCreatorsFeatureText: ['Match with up to ', `${features.get('influencers.matches.limit') || 'unlimited'} creators`, ' per campaign'],
            completedContentStatsText: [`${features.get('completed.content.stats') ? 'Measure post performance (reach, engagement, likes)': ''}`],
            planType: type,
            rightSideImg: '',
            showBackBtn: true,
            showFilters: true,
            titleSubtext: trialAvailable ? `Free ${subscription.trial_days} day trial, cancel any time` : '',
            titleText: trialAvailable ? 'Start your free #gifted Trial' : 'Get more from #gifted',
            twoColumns: true,
            onBack: onBack,
            onNext: onNext,
        }
    }

    choosePlanPage(brand: BrandProfile, availableSubscriptions: Subscription[], type: PlanType, onNext: () => void, onBack: () => void): ChoosePlanPage {
        const planDefinition = this.findPlanDefinition(type);
        const subscription = this.findSubscription(availableSubscriptions, type, 'YEAR');
        const features = this.features(subscription);
        const trialAvailable = this.isTrialAvailable(brand);
        return {
            fullSized: false,
            showBackBtn: true,
            twoColumns: true,
            titleText: trialAvailable ? `Free ${subscription.trial_days} day trial, cancel any time` : 'Pricing',
            titleSubtext: trialAvailable ? `Free ${subscription.trial_days} day trial, cancel any time` : 'Pricing',
            featureDescriptionText: ['You have selected', planDefinition.name],
            nextButtonText: trialAvailable ? `Start free ${subscription.trial_days} day trial` : `Upgrade to ` + planDefinition.shortName,
            trialDays: subscription.trial_days,
            campaignFeatureText: ['Post ', `${features.get('gifts.create.limit') || 'unlimited'} campaigns`, ' per month'],
            giftCreatorsFeatureText: ['Gift up to ', `${features.get('influencers.accept.limit') || 'unlimited'} creators`, ' per campaign'],
            matchCreatorsFeatureText: ['Match with up to ', `${features.get('influencers.matches.limit') || 'unlimited'} creators`, ' per campaign'],
            captureStoriesFeaturesText: [features.get('content.stories.enabled') ?  'Capture Instagram, TikTok, and YouTube posts plus Instagram stories' : 'Capture Instagram, TikTok, and YouTube posts'],
            completedContentStatsText: [`${features.get('completed.content.stats') ? 'Measure post performance (reach, engagement, likes)': ''}`],
            choosePlanPage: true,
            showFilters: true,
            rightSideImg: '',
            onNext: onNext,
            onBack: onBack,
        };
    }

    threePlanPage(brand: BrandProfile, availableSubscriptions: Subscription[], type: PlanType, onNext: () => void, onBack: () => void): ThreePlanPage {
        const planDefinition = this.findPlanDefinition(type);
        const subscription = this.findSubscription(availableSubscriptions, type, 'YEAR');
        const features = this.features(subscription);
        const trialAvailable = this.isTrialAvailable(brand);
        return {
            threePlanPage: true,
            fullSized: true,
            showBackBtn: true,
            twoColumns: false,
            titleText: trialAvailable ? `Free ${subscription.trial_days} day trial, cancel any time` : 'Pricing',
            titleSubtext: '',
            featureDescriptionText: ['You have selected', planDefinition.name],
            nextButtonText: trialAvailable ? `Start free ${subscription.trial_days} day trial` : `Upgrade to ` + planDefinition.shortName,
            trialDays: subscription.trial_days,
            campaignFeatureText: ['Post ', `${features.get('gifts.create.limit') || 'unlimited'} campaigns`, ' per month'],
            giftCreatorsFeatureText: ['Gift up to ', `${features.get('influencers.accept.limit') || 'unlimited'} creators`, ' per campaign'],
            matchCreatorsFeatureText: ['Match with up to ', `${features.get('influencers.matches.limit') || 'unlimited'} creators`, ' per campaign'],
            captureStoriesFeaturesText: [features.get('content.stories.enabled') ?  'Capture Instagram, TikTok, and YouTube posts plus Instagram stories' : 'Capture Instagram, TikTok, and YouTube posts'],
            completedContentStatsText: [`${features.get('completed.content.stats') ? 'Measure post performance (reach, engagement, likes)': ''}`],
            showFilters: true,
            rightSideImg: '',
            onNext: onNext,
            onBack: onBack,
        };
    }

    paymentPage(brand: BrandProfile, availableSubscriptions: Subscription[], type: PlanType, onNext: () => void, onBack: () => void): PaymentPage {
        const planDefinition = this.findPlanDefinition(type);
        const subscription = this.findSubscription(availableSubscriptions, type, 'YEAR');
        const features = this.features(subscription);
        const trialAvailable = this.isTrialAvailable(brand);
        return {
            fullSized: false,
            showBackBtn: true,
            twoColumns: true,
            titleText: trialAvailable ? 'Start your free #gifted Trial' : 'Get more from #gifted',
            //@ts-ignore
            titleSubtext: trialAvailable ? `Free ${subscription.trial_days} day trial, cancel any time` : null,
            trialDays: subscription.trial_days,
            featureDescriptionText: [planDefinition.description],
            //@ts-ignore
            nextButtonText: trialAvailable ? `Start free ${subscription.trial_days} day trial` : 'Upgrade to ' + planDefinition.shortName,
            //@ts-ignore
            campaignFeatureText: ['Post ', `${features.get('gifts.create.limit') || 'unlimited'} campaigns`, ' per month'],
            //@ts-ignore
            giftCreatorsFeatureText: ['Gift up to ', `${features.get('influencers.accept.limit') || 'unlimited'} creators`, ' per campaign'],
            //@ts-ignore
            matchCreatorsFeatureText: ['Match with up to ', `${features.get('influencers.matches.limit') || 'unlimited'} creators`, ' per campaign'],
            captureStoriesFeaturesText: [features.get('content.stories.enabled') ?  'Capture Instagram, TikTok, and YouTube posts plus Instagram stories' : 'Capture Instagram, TikTok, and YouTube posts'],
            completedContentStatsText: [`${features.get('completed.content.stats') ? 'Measure post performance (reach, engagement, likes)': ''}`],
            paymentPage: true,
            onNext: onNext,
            onBack: onBack,
        }
    }

    fullSizedPaymentPage(brand: BrandProfile, availableSubscriptions: Subscription[], type: PlanType, onNext: () => void, onBack: () => void): FullSizedPaymentPage {
        const planDefinition = this.findPlanDefinition(type);
        const subscription = this.findSubscription(availableSubscriptions, type, 'YEAR');
        const features = this.features(subscription);
        const trialAvailable = this.isTrialAvailable(brand);
        return {
            fullSized: true,
            showBackBtn: true,
            rightSideImg: '',
            showFilters: false,
            twoColumns: false,
            titleText: trialAvailable ? 'Start your free #gifted Trial' : 'Get more from #gifted',
            //@ts-ignore
            titleSubtext: 'Create unique photos, videos, reviews, testimonials, and social buzz.',
            trialDays: subscription.trial_days,
            featureDescriptionText: [planDefinition.description],
            campaignFeatureText: ['Post ', `${features.get('gifts.create.limit') || 'unlimited'} campaigns`, ' per month'],
            //@ts-ignore
            giftCreatorsFeatureText: ['Gift up to ', `${features.get('influencers.accept.limit') || 'unlimited'} creators`, ' per campaign'],
            //@ts-ignore
            matchCreatorsFeatureText: ['Match with up to ', `${features.get('influencers.matches.limit') || 'unlimited'} creators`, ' per campaign'],
            captureStoriesFeaturesText: [features.get('content.stories.enabled') ?  'Capture Instagram, TikTok, and YouTube posts plus Instagram stories' : 'Capture Instagram, TikTok, and YouTube posts'],
            completedContentStatsText: [`${features.get('completed.content.stats') ? 'Measure post performance (reach, engagement, likes)': ''}`],
            //@ts-ignore
            nextButtonText: trialAvailable ? `Start free ${subscription.trial_days} day trial` : 'Upgrade to ' + planDefinition.shortName,
            //@ts-ignore
            paymentPage: true,
            onNext: onNext,
            onBack: onBack,
        }
    }

    successPage(brand: BrandProfile, availableSubscriptions: Subscription[], type: PlanType, hasGifts: boolean, onSuccess: () => void): SuccessPage {
        const planDefinition = this.findPlanDefinition(type);
        const trialAvailable = this.isTrialAvailable(brand);
        return {
            fullSized: false,
            twoColumns: false,
            titleText: 'Welcome to ' + planDefinition.name,
            titleSubtext: trialAvailable ? 'Your gifted trial begins today' : 'Your ' + planDefinition.name + ' plan begins today',
            nextButtonText: hasGifts ? 'Continue to #gifted' : 'Create your first #gift',
            confetti: true,
            rightSideImg: '',
            onBack: null,
            showBackBtn: false,
            showFilters: false,
            onNext: onSuccess,
        };
    }

    isTrialAvailable(brand?: BrandProfile | null, subscription?: Subscription) {

        if (!subscription?.trial_days || brand?.has_trialed ) {
            return false;
        }
        const trial_days = subscription?.trial_days || 0;
        return trial_days !== 0;
    }
}

const subscriptionService = new SubscriptionService();
export default subscriptionService;
