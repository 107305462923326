import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Header = styled.div`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 40px;
    letter-spacing: -0.75px;
    text-align: center;
    color: var(--grey-9);
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 24px 24px 34px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background: var(--background-alt);
    margin-bottom: 24px;

    @media (max-width:900px) and (min-width:0px) {
        width: 100% !important;
    }
`;

interface Props {
    width?: string;
}

export function InfoCard({
    children,
    width = '619px'
}: PropsWithChildren<Props>) {
    return (
        <Container data-testid={'info-card-component'} style={{ width: width }}>
            {children}
        </Container>
    );
}
