import {AnimatePresence, motion} from "framer-motion";
import {ReactNode, useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import styled from "styled-components";
import {Button, ReviewModal} from "app/components";
import {InfluencerChat} from 'app/pages/GiftViewPage/InfluencerChat';
import {useAppDispatch, useAppSelector} from "store";
import {waveActions} from "../../../store/actions/waveActions";
import {Simulate} from "react-dom/test-utils";
import select = Simulate.select;
import {appActions} from "../../../store/actions/appActions";

interface Props {
    show?: boolean;
    outstandingReviews: OutstandingInfluencerReview[];
    brand: BrandProfile;
    onClose: () => void;
    refresh: () => void;
}

const OutstandingReviewsModal = ({show, outstandingReviews: initialReviews, brand, onClose, refresh}: Props) => {
    const [outstandingReviews, setOutstandingReviews] = useState<OutstandingInfluencerReview[]>(initialReviews);
    const requiredReviewCount = initialReviews.length;
    const [reviewedWaveCount, setReviewedWaveCount] = useState(0);
    const [modalState, setModalState] = useState<{
        show: boolean,
        influencer?: Influencer,
        gift_uid?: string,
        completed?: boolean,
        loading?: boolean
    }>({ show: false });
    
    const dispatcher = useAppDispatch();

    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    const handleClose = () => {
        if (reviewedWaveCount >= requiredReviewCount) {
            onClose();
        }
    };

    const handleSubmitReview = (form: ReviewForm) => {
        setModalState((prev) => ({ ...prev, loading: true}));

        if (modalState.influencer && modalState.gift_uid) {
            dispatcher(waveActions.submitReview(
                modalState.gift_uid,
                modalState.influencer.uid, 
                form, 
                () => {
                    setOutstandingReviews(prev => 
                        prev.filter(review => 
                            review.influencer.uid !== modalState.influencer?.uid || 
                            review.gift_uid !== modalState.gift_uid
                        )
                    );
                    
                    if (selectedWave?.influencer.uid === modalState.influencer?.uid && 
                        selectedWave?.gift_uid === modalState.gift_uid) {
                        setSelectedWave(null);
                    }

                    const newReviewCount = reviewedWaveCount + 1;
                    setReviewedWaveCount(newReviewCount);

                    if (newReviewCount >= requiredReviewCount) {
                        sessionStorage.setItem('outstandingReviewsCompleted', 'true');
                        onClose();
                    }

                    setModalState((prev) => ({
                        ...prev,
                        completed: true,
                        loading: false
                    }));
                    
                    refresh();
                }
            )).catch((error: any) => {throw error});
        } else {
            setModalState((prev) => ({
                ...prev,
                influencer: undefined,
                completed: false,
                show: false,
                loading: false,
                gift_uid: undefined
            }));
        }
    }

    const [selectedWave, setSelectedWave] = useState<OutstandingInfluencerReview | null>(null);

    const {
        wave: {
            view,
            loading: waveLoading,
        }
    } = useAppSelector((state) => state);

    useEffect(() => {
        setModalState({...modalState, gift_uid: view?.gift?.uid})
    }, [view]);

    const handleViewCollab = async (review: OutstandingInfluencerReview) => {
        dispatcher(waveActions.setLoading(true));
        setSelectedWave(review);
        await dispatcher(waveActions.getWaveItem(
            review.gift_uid as string,
            review.uid as string,
            true,
            true
        )).catch((error: any) => {throw error});
        dispatcher(waveActions.setLoading(false));
    }

    return renderToPortal(
        <>
            <AnimatePresence>
                {show && outstandingReviews.length > 0 && (
                    <Container
                        {...containerAnimation}
                        data-testid={'gift-modal-component'}
                        onClick={(e) => {
                            if (e.target === e.currentTarget && reviewedWaveCount >= requiredReviewCount) {
                                onClose();
                            }
                        }}
                    >
                        <Content
                            {...contentAnimation}
                            ref={contentRef}
                            $showChat={!!selectedWave}
                        >
                            <LeftPanel>
                                <Body>
                                    <h1>You must Review {outstandingReviews.length} more Collaboration{(requiredReviewCount - reviewedWaveCount) > 1 ? "s" : ""}</h1>
                                    <ReviewList>
                                        {outstandingReviews.map((review: OutstandingInfluencerReview) => (
                                            <ReviewItem key={review.uid} className={selectedWave?.uid === review.uid ? 'active' : ''}>
                                                <ReviewHeader>
                                                    <InfluencerInfo>
                                                        <h3>{review.influencer.name}</h3>
                                                        <p>{review.gift_name}</p>
                                                    </InfluencerInfo>
                                                </ReviewHeader>
                                                <ReviewActions>
                                                    <Button
                                                        theme={"main"}
                                                        size="small"
                                                        onClick={() => {
                                                            setModalState((prev) => ({
                                                                ...prev,
                                                                influencer: {
                                                                    uid: review.influencer.uid,
                                                                    name: review.influencer.name
                                                                } as Influencer,
                                                                gift_uid: review.gift_uid,
                                                                show: true
                                                            }));
                                                        }}
                                                    >
                                                        Review
                                                    </Button>
                                                    <Button
                                                        theme={"outline"}
                                                        size="small"
                                                        onClick={() => {
                                                            void handleViewCollab(review);
                                                        }}
                                                    >
                                                        View Chat
                                                    </Button>
                                                </ReviewActions>
                                            </ReviewItem>
                                        ))}
                                    </ReviewList>
                                    {reviewedWaveCount >= requiredReviewCount && (
                                        <DoneButton
                                            theme="main"
                                            onClick={handleClose}
                                        >
                                            Done
                                        </DoneButton>
                                    )}
                                </Body>
                            </LeftPanel>

                            {selectedWave && view && (
                                <RightPanel>
                                    <CloseButton onClick={() => {
                                        setSelectedWave(null);
                                    }}>×</CloseButton>
                                    <InfluencerChat
                                        data={view}
                                        brandName={brand?.name}
                                        loadingView={waveLoading}
                                        onSendAttachment={(waveId, file, wave) => {
                                            dispatcher(waveActions.sendMessage(selectedWave.gift_uid, waveId, file))
                                                .catch((error: any) => {throw error});
                                        }}
                                        onSubmitReview={() => {
                                            setModalState((prev) => ({
                                                    ...prev,
                                                    influencer: selectedWave?.influencer,
                                                    gift_uid: selectedWave?.gift_uid,
                                                    show: true
                                                })
                                            )}
                                        }

                                        onSendMessage={(waveId, message, wave) => {
                                            dispatcher(waveActions.sendMessage(selectedWave.gift_uid, waveId, message))
                                                .catch((error: any) => {throw error});
                                        }}
                                        onResendMessage={(waveId, bubbleId) => {
                                            dispatcher(waveActions.resendMessage(selectedWave.gift_uid, waveId, bubbleId))
                                                .catch((error: any) => {throw error});
                                        }}
                                        onViewProfile={() => {}}
                                        influencer={view.influencer}
                                        onStatusUpdate={() => {}}
                                    />
                                </RightPanel>
                            )}
                        </Content>
                    </Container>
                )}
            </AnimatePresence>

            <ReviewModal
                {...modalState}
                onClose={() => setModalState((prev) => ({
                    ...prev,
                    influencer: undefined,
                    completed: false,
                    show: false,
                }))}
                onSubmit={handleSubmitReview}
            />
        </>
    );
}

export default OutstandingReviewsModal;

const ReviewList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    padding: 16px;
`;

const ReviewItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: var(--background);
    border-radius: 8px;
    box-shadow: var(--shadow-1);
    
    &.active {
        border: solid 2px var(--blue);
    }
`;

const ReviewHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
    min-width: 0;
`;

const InfluencerInfo = styled.div`
    h3 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    p {
        font-size: 14px;
        color: var(--text-secondary);
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const ReviewActions = styled.div`
    display: flex;
    gap: 8px;
    flex: 0 0 auto;
    
    button {
        min-width: 100px;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    
    h1 {
        text-align: center;
        margin-bottom: 8px;
    }

    @media (max-height:711px) and (min-height:0px) {
        padding-top: 24px;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 80px;

    @media (max-height:711px) and (min-height:0px) {
        margin-top: 24px;
    }
`;

const Content = styled.div<{ $showChat?: boolean }>`
    display: flex;
    flex-direction: row;
    width: ${props => props.$showChat ? '90vw' : ''};
    height: 80vh;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
    transition: width 0.3s ease;
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: var(--modal-index);

    button {
        margin-top: auto;
    }
`;

const LeftPanel = styled.div`
    flex: 0 0 600px;
    border-right: 1px solid var(--grey-4);
    overflow-y: auto;
    padding-right: 24px;
`;

const RightPanel = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow: hidden;

    .influencer-chat {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 24px;
    right: 24px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 1;
    color: var(--grey-7);
    
    &:hover {
        color: var(--blue);
    }
`;

const ReviewProgress = styled.div`
    color: var(--grey-7);
    font-size: 14px;
    margin: 8px 0 16px;
`;

const DoneButton = styled(Button)`
    width: 100%;
    margin-top: 16px;
`;
