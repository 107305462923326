import {
    FormEvent,
    PropsWithChildren,
    useMemo,
    useState,
    useEffect,
} from 'react';
import styled from 'styled-components';
import {
    PaymentMethodResult,
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElement,
    StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js'
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements, Elements
} from '@stripe/react-stripe-js';
/**
 * Must use this to avoid performance issues where stripe will contact itself on every URL change
 * https://stackoverflow.com/questions/45718026/stripe-js-making-duplicate-requests-new-requests-on-state-change
 */
import { loadStripe } from '@stripe/stripe-js/pure';
import { useDebouncyEffect } from 'use-debouncy';


import { API } from 'api';
import { Helpers } from 'utils';
import {
    Alert,
    Button,
    Checkbox,
    FormInput,
    FormLabel,
    StatusLoader
} from 'app/components'
import { option } from './stripeElementOption';
import {ClientSettings} from "../../../services/settingsService";
import {useAppSelector} from "../../../store";
import { CardAEIcon, CardVisaIcon, CardMasterCardIcon, CardIcon } from 'app/icons';
import errorReporter from 'services/errorReporter';

interface Props {
    brand: BrandProfile;
    code: string;
    onSuccess: () => void;
    onCancel: () => void;
    showLoader: boolean;
    shownInModal: boolean;
    previewSubscription?: (brand: BrandProfile, code: string, coupon: string) => void;
    error?: string;
}

interface BillingFormProps {
    brand: BrandProfile;
    code: string;
    onSuccess: () => void;
    onCancel: () => void;
    showLoader: boolean;
    shownInModal: boolean;
    previewSubscription?: (brand: BrandProfile, code: string, coupon: string) => void;
    error?: string;
    billing?: any;
}

const stripeFonts = [{
    src: 'url(../assets/fonts/Mont-Regular.ttf) format(truetype)',
    family: 'Mont',
    fontWeight: 400
}];


export function CheckoutForm({
                                 brand,
                                 code,
                                 shownInModal,
                                 showLoader,
                                 children,
                                 onSuccess,
                                 previewSubscription,
                                 onCancel,
                                 error
                             }: PropsWithChildren<Props>) {

    const {
        global: {
            settings
        },
        profile: {
            billing
        }
    } = useAppSelector((state) => state);

    const stripePromise = useMemo(() => loadStripe((settings as ClientSettings).stripe.key), [])

    return (
      <Elements stripe={stripePromise} options={{ fonts: stripeFonts }}>
        <CheckoutFormInternal
          brand={brand}
          code={code}
          billing={billing}
          onSuccess={onSuccess}
          onCancel={onCancel}
          showLoader={showLoader}
          shownInModal={shownInModal}
          previewSubscription={previewSubscription}
          error={error}
        >
          {children}
        </CheckoutFormInternal>
      </Elements>
    );
}

export function CheckoutFormInternal({
    brand,
    code,
    shownInModal,
    showLoader,
    children,
    onSuccess,
    previewSubscription,
    onCancel,
    error,
    billing
}: PropsWithChildren<BillingFormProps>) {
    const stripe = useStripe();
    const elements = useElements();

    const [billingForm, setBillingForm] = useState<BillingForm>({
        name: '',
        coupon: '',
        use_coupon: false,
    });

    const [stripeForm, setStripeForm] = useState<Dynamic>({
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false
    });

    const [loading, setLoading] = useState<boolean>(false);

    const [status, setStatus] = useState<string>('idle');
    const [errorMessage, setErrorMessage] = useState<string|null>(null);

    const disabledSubmit = useMemo(() => {
        if (
            Helpers.isEmpty(stripeForm) ||
            Object.values(stripeForm).some((v) => !v)
        ) {
            return true;
        }

        if (Helpers.isEmpty(billingForm.name)) {
            return true;
        }

        if (billingForm.use_coupon && Helpers.isEmpty(billingForm.coupon)) {
            return true;
        }

        return false;
    }, [billingForm, stripeForm]);

    useEffect(() => {
        if (brand.affiliate_id) {
            handleChange('use_coupon', true)

            handleChange('coupon', "AFFILIATE50")

        }
    }, [brand.affiliate_id])

    const getMessage = () => {
        switch (status) {
            case 'success':
                return 'Request sucessful';

            case 'error':
                return 'Request failed';

            case 'loading':
                return 'Updating brand subscription card';

            default: return '';
        }
    }

    const handleServerResponse = async (response: StripePaymentIntentResponse, data: SubscriptionForm) => {
        if (response.error) {
            // Show error from server on payment billingForm
            setStatus('error');
            setErrorMessage(response.error as string);
        } else if (response.requires_action) {
            try {
                if (stripe) {
                    const { error: errorAction, paymentIntent } =
                        await stripe.handleCardAction(response.payment_intent_client_secret as string);

                    if (errorAction) {
                        // Throw the error from Stripe.js and
                        // let the catch handle the error
                        throw new Error(errorAction?.message || 'Unknown stripe error');
                    } else {
                        if (!brand) {
                            throw new Error('missing brand');
                        }
                        const postData = {
                            code: code,
                            payment_intent_id: paymentIntent?.id,
                            expiry_month: data.expiry_month,
                            expiry_year: data.expiry_year,
                            name: data.name,
                            promotion_code: billingForm.use_coupon ? billingForm?.coupon : undefined,
                        };
                        await API.Profile.createOrUpdateSubscription(brand, postData);

                        setStatus('error');

                        // if (onUpdateSuccess) {
                        //     onUpdateSuccess();
                        // }
                    }
                } else {
                    // Throw an error if stripe has not been initialized and
                    // let the catch handle the error
                    throw new Error('Stripe not initialized');
                }
            } catch (err: any) {
                setStatus('error');
                if (err?.message) {
                    setErrorMessage(err.message as string);
                } else {
                    setErrorMessage('Unknown error occurred at checkout')
                }
            }
        }
    }

    interface StripeError {
        error: {
            code: string;
            decline_code: string;
            doc_url: string;
            message: string;
            param: string;
            request_log_url: string;
            type: string;
        }
        paymentMethod: any;
    }

    const stripePaymentMethodHandler = async (methodResult: PaymentMethodResult | StripeError) => {
        if (methodResult.error) {
            setStatus('error');
            setErrorMessage(methodResult.error.message || 'Unkown Stripe error, please try again later.')
        } else {
            try {
                const form = {
                    code: code,
                    payment_method_id: methodResult.paymentMethod.id,
                    card_last_four: methodResult.paymentMethod.card?.last4,
                    card_brand: methodResult.paymentMethod.card?.brand,
                    expiry_month: methodResult.paymentMethod.card?.exp_month,
                    expiry_year: methodResult.paymentMethod.card?.exp_year,
                    name: methodResult.paymentMethod.billing_details.name,
                    promotion_code: billingForm.use_coupon ? billingForm?.coupon : undefined,
                } as SubscriptionForm;

                if (!brand) {
                    throw new Error('brand was missing');
                }
                const result = await API.Profile.createOrUpdateSubscription(brand, form);
                await handleServerResponse(result, form);
                onSuccess();
            } catch (err: any) {
                if (err.response.data.message) {
                    setErrorMessage(err.response.data.message as string);
                } else {
                    setErrorMessage('There was an error when completing payment')
                }

                if (showLoader) {
                    setStatus('error');
                }
            }
        }

        setLoading(false);

        setTimeout(() => {
            setStatus('idle');
        }, 2500);
    }

    const updateWithCurrentCard = async () => {
        setLoading(true);
        setStatus('loading');
        try {
            const form = {
                code: code,
                payment_method_id: billing.payment_method_id,
                card_last_four: billing.card_last_four,
                card_brand: billing.card_brand,
                expiry_month: billing.expiry_month,
                expiry_year: billing.expiry_year,
                name: billing.name,
                promotion_code: billingForm.use_coupon ? billingForm?.coupon : undefined,
            } as SubscriptionForm;

            if (!brand) {
                throw new Error('brand was missing');
            }
            const result = await API.Profile.createOrUpdateSubscription(brand, form);
            await handleServerResponse(result, form);
            onSuccess();
        } catch (err: any) {
            if (err.response.data.message) {
                setErrorMessage(err.response.data.message as string);
            } else {
                setErrorMessage('There was an error when completing payment')
            }

            if (showLoader) {
                setStatus('error');
            }
        }

        setLoading(false);

        setTimeout(() => {
            setStatus('idle');
        }, 2500);
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        setErrorMessage(null);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);
        setStatus('loading');

        stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
            billing_details: { name: billingForm.name }
        }).then(result => {
            return stripePaymentMethodHandler(result);
        }).catch(error => {
            throw error;
        });
    };

    const handleChange = (
        name: string,
        value: string | boolean
    ) => {
        setBillingForm((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const handleElementChange = (
        event: StripeCardCvcElementChangeEvent
            | StripeCardExpiryElementChangeEvent
            | StripeCardNumberElementChangeEvent
    ) => {
        const { elementType, complete } = event;

        setStripeForm((prev) => ({
            ...prev,
            [elementType]: complete
        }));
    }

    useEffect(() => {
        if (previewSubscription) {
            previewSubscription(brand, code, billingForm.coupon);
        }
    }, [code])

    useDebouncyEffect(() => {
        if (previewSubscription) {
            previewSubscription(brand, code, billingForm.coupon);
        }
    },500, [billingForm.coupon])

    const [selectedChangeCard, setSelectedChangeCard] = useState<boolean>(false);

    const cardImage = () => {
        if (billing.card_brand === 'visa') {
            return <CardVisaIcon />
        } else if (billing.card_brand === 'mastercard') {
            return <CardMasterCardIcon />
        } else if (billing.card_brand === 'american_express') {
            return <CardAEIcon />
        } else {
            return <CardIcon />
        }
    }

    const handleChangeView = (e: any) => {
        e.preventDefault();

        setSelectedChangeCard(!selectedChangeCard);
    }
    
    const handleNext = (e:any) => {
        e.preventDefault();

        if (billing?.card_last_four && !selectedChangeCard) {
            updateWithCurrentCard().catch((e: any) => {
                errorReporter.report('Problem updating subscription with current card', e);
            })
        } else {
            handleSubmit(e as FormEvent<HTMLFormElement>);
        }
    }

    return (
        <Container>
            {children}

            {errorMessage && (
                <Alert type={"error"} title={errorMessage} />
            )}

            <Form onSubmit={handleSubmit}>
                {billing?.card_last_four && !selectedChangeCard ? (
                    <>
                        <label>Payment method</label>
                        <CurrentCardBox>
                            <ImageAndLastFour>
                                {cardImage()}
                                <div>
                                    {billing.card_brand} ending in {billing.card_last_four}
                                </div>
                            </ImageAndLastFour>

                            <ChangeMethodButton onClick={(e) => handleChangeView(e)}>
                                Change
                            </ChangeMethodButton>
                        </CurrentCardBox>
                    </>
                ) : (
                    <>
                        <FormInput
                            type={'text'}
                            name={'name'}
                            label={'Full Name'}
                            placeholder={'Enter your Full Name'}
                            value={billingForm.name}
                            required={true}
                            onChange={handleChange}
                        />

                        <StripeElement>
                            <FormLabel required={true}>
                                Card number
                            </FormLabel>

                            <Content>
                                <CardNumberElement
                                    options={{ ...option }}
                                    onChange={handleElementChange}
                                />
                            </Content>
                        </StripeElement>

                        <FormInline>
                            <StripeElement>
                                <FormLabel required={true}>
                                    Expiration Date
                                </FormLabel>

                                <Content>
                                    <CardExpiryElement
                                        options={{ ...option }}
                                        onChange={handleElementChange}
                                    />
                                </Content>
                            </StripeElement>

                            <StripeElement>
                                <FormLabel required={true}>
                                    CVV
                                </FormLabel>

                                <Content>
                                    <CardCvcElement
                                        options={{ ...option }}
                                        onChange={handleElementChange}
                                    />
                                </Content>
                            </StripeElement>
                        </FormInline>

                    </>
                )}

                <FormInline className="coupon-container">
                    <Checkbox
                        checked={billingForm.use_coupon}
                        onChange={(value) => handleChange('use_coupon', !!value)}
                    >
                        I have a coupon
                    </Checkbox>

                    {billingForm.use_coupon && (
                        <FormInput
                            type={'text'}
                            name={'coupon'}
                            label=""
                            placeholder={'Enter coupon'}
                            value={billingForm.coupon}
                            className="coupon-input"
                            required={billingForm.use_coupon}
                            onChange={handleChange}
                        />
                    )}
                </FormInline>

                {error && <PreviewError>{error}</PreviewError>}


                {billing?.card_last_four && selectedChangeCard && (
                    <ChangeMethodButton className='at-bottom' onClick={(e) => handleChangeView(e)}>
                        Use existing card
                    </ChangeMethodButton>
                )}

                {onCancel && (
                    <Actions>
                        {!shownInModal && (
                            <StyledButton
                                loading={loading}
                                onClick={(event) => {
                                    onCancel && onCancel();
                                    event.preventDefault();
                                }}
                            >
                                Cancel
                            </StyledButton>
                        )}

                        <StyledButton
                            className={`${shownInModal ? 'flow-btn-version' : ''} ${shownInModal && billingForm.use_coupon ? 'less-transform' : ''}`}
                            loading={loading}
                            disabled={disabledSubmit && (!!selectedChangeCard && billing?.card_last_four)}
                            onClick={(e) => handleNext(e)}
                        >
                            Next
                        </StyledButton>
                    </Actions>
                )}
                
            </Form>
            
            {showLoader && (
                <StatusLoader
                    show={showLoader &&
                        status !== 'idle'}
                    status={status}
                    title={'Subscription and Billing'}
                    message={getMessage()}
                />
            )}
        </Container>
    );
}

const CurrentCardBox = styled.div`
    display: flex;
    // align-items: center;
    justify-content: space-between;
    background-color: var(--grey-1);
    padding: 0px 16px;
    padding-top: 12px;
    border-radius: 16px;
    border: 1px solid var(--grey-grey-3, #E7EBFB);

    div {
        padding-top: 11px;
    }
`;

const ImageAndLastFour = styled.div`
    display: flex;
    padding-top: 0px !important;
    color: var(--grey-6);
    font-size: 14px;
`

const ChangeMethodButton = styled.button`
    background: none;
    border: none;
    color: var(--blue);
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 17px;

    &.at-bottom {
        height: 12px;
        margin-bottom: 0px;
    }
`

const PreviewError = styled.p`
    color: red;
    margin: 0px !important;
    padding: 0px !important;
`

const StyledButton = styled(Button)`
    margin-top: 8px;
    width: 186px;

    @media (max-width:900px) and (min-width:0px) {
        width: 100% !important;
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    height: 56px;
    padding: 0 12px;
    border: 1.5px solid var(--grey-2);
    border-radius: 14px;
    background-color: var(--grey-1);
    color: var(--grey-9);
    transition: border-color 300ms ease;
    overflow: hidden;

    div {
        width: 100%;
    }

    iframe {
        width: 100%;
    }

    svg {
        flex-shrink: 0;
        margin-left: -1.5px;
    }
`

const StripeElement = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
`;

const FormInline = styled.div`
    display: flex;
    column-gap: 24px;

    > div {
        width: 100%;
    }

    &.coupon-container {
        height: 56px;

        .coupon-input {
            width: 191px;
            margin-left: 33px;
        }
    }

`;

const Form = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px !important;
    }

    @media (max-width:900px) and (min-width:0px) {
        padding-bottom: 12px;

        .coupon-container {
            width: 100% !important;
            flex-direction: column !important;

            .coupon-input {
                width: 100% !important;
                margin-left: 0px;
                margin-top: 6px;
                margin-bottom: 6px;
            }
    
        }
    }

`;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
`;

const Actions = styled.div`
    align-self: center;
    display: flex;
    align-items: center;
    column-gap: 24px;
    margin-top: 24px;
    padding: 0 36px;
    margin-top: 108px;

    .flow-btn-version {
        width: 398px;
        transform: translateY(70px);
        transition: background 250ms ease;

        &:active, &:focus {
            transform: translateY(70px);
        }

        @media (max-width:900px) and (min-width:0px) {
            width: 60vw !important;
        }

    }

    .less-transform {
        transform: translateY(70px);
        transition: background 250ms ease;

        &:active, &:focus {
            transform: translateY(70px);
        }
    }
`;
