import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {useNavigate, useSearchParams} from 'react-router-dom';

import { Actions, useAppDispatch, useAppSelector } from 'store';
import { Helpers } from 'utils';
import {Button, Loader} from 'app/components';
import {
    BrandProfileForm,
    RepresentativeProfileForm,
    SignUpForm,
    SocialAccountsForm,
    ConnectShopifyForm,
    InviteTeamForm,
    CodeVerificationForm
} from 'app/forms';
import { AngleLeftIcon } from 'app/icons';
import { PublicLayout } from 'app/layouts';
import { SignUpPageHeader } from './Header';
import { DisabledSignUpForm } from 'app/forms/DisabledSignUpForm';
import AnalyticsEvents from "../../../services/analyticsEvents";
import settingsService, {ClientSettings} from "../../../services/settingsService";
import ReactGA from 'react-ga4';

const GoBack = styled(Button)`
    position: absolute;
    top: 32px;
    left: 32px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: auto;

    p {
        font-size: 14px;
        line-height: 24px;
        margin: 0;
        text-align: center;
    }
`;

export function SignUpPage() {
    const navigate = useNavigate();
    const dispatcher = useAppDispatch();
    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const [urlParams] = useSearchParams();

    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    console.log('code', code)

    const [signedUpEmail, setSignedUpEmail] = React.useState<string>('');

    const {
        auth: {
            signup: { step },
            loading: authLoading
        },
        global: { alert },
        profile: {
            representative,
            brand,
            loading: profileLoading
        }
    } = useAppSelector((state) => state);

    useEffect(() => {
        document
            .documentElement
            .scrollTo({
                top: 0,
                behavior: 'smooth'
            });
    }, [step]);

    useEffect(() => {
        dispatcher(Actions.App.setAlert(null));

        return () => {
            dispatcher(Actions.App.setAlert(null));
        }
    }, [dispatcher, step]);

    const completeSignUp = () => {
        dispatcher(Actions.Profile.completeSignUp());
    }

    const analyticsEvents = useMemo(() => new AnalyticsEvents(settings as ClientSettings), [settings]);

    useEffect(() => {
        switch (step) {
            case 1:
                analyticsEvents.onboardingStarted();
                break;
            case 2:
                analyticsEvents.onboardingSocials();
                break;
            case 3:
                analyticsEvents.onboardingUserProfile();
                break;
            case 4:
                analyticsEvents.onboardingShopifyProfile();
                break;
            default:
                null;
                break;
        }
    }, [step]);

    const createAccount = (form: SignUpForm) => {
        setSignedUpEmail(form.email);
        void dispatcher(Actions.Auth.createAccount(form, (result) => {
            analyticsEvents.signup(form.email, result.brand_representative_id as string, result.status);
            ReactGA.event({
                category: "custom event",
                action: "signup",
                label: "Brand signed up", // optional
            });
        }));
    }

    const createBrandProfile = async (form: BrandForm) => {
        console.log(form)
        console.log('First settings', settings);
        if (!form.doesnt_shopify) {
            form.doesnt_shopify = false;
        }

        if (brand?.uid) {
            // @ts-ignore
            void dispatcher(Actions.Profile.setBrandProfile(form));
            await settingsService.loadSettings(brand?.uid);
            console.log('grabbing settings')
        } else {
            // @ts-ignore
            console.log('grabbing settings 159')
            void dispatcher(Actions.Profile.createBrandProfile(form));
        }
    }

    const setBrandProfileLogo = (file: File) => {
        void dispatcher(Actions.Profile.setBrandProfileLogo(file));
    }

    const setRepresentativeProfile = (form: RepresentativeForm) => {
        void dispatcher(Actions.Profile.setRepresentativeProfile(form, true));
    }

    const setRepresentativeProfileImage = (file: File) => {
        void dispatcher(Actions.Profile.setRepresentativeProfileImage(file));
    }

    const setBrandProfileSocial = (form: SocialProfileForm) => {
        void dispatcher(Actions.Profile.setBrandProfileSocial(form, true));
    }

    const setShopifyProfile = () => {
        dispatcher(Actions.Auth.setSignUpStep(5))
    }

    const finishInvite = () => {
        completeSignUp();
    }

    const handleVerificationSubmit = () => {
        dispatcher(Actions.Auth.setSignUpStep(5))
    }

    const verifyForm = () => {
        return (
            //@ts-ignore
            <CodeVerificationForm data={{code: code?.split('')}} onSubmit={() =>  handleVerificationSubmit()} digits={5} email={representative?.email} repId={representative?.uid}>
                <SignUpPageHeader
                    title={'Verify your account'}
                    desc={'Please enter the 5-digit code sent to your email'}
                />
            </CodeVerificationForm>
        )
    }

    const signupForm = () => {
        const disabledSignUps = !urlParams.get('signup') && !settings?.features.brandSignup;
        return (
            disabledSignUps ? (
                <DisabledSignUpForm>
                    <SignUpPageHeader
                        title={'Start #gifting today'}
                        desc={'Create unique photos, videos, reviews, testimonials, and social buzz through the power of influencer gifting.'}
                    />

                </DisabledSignUpForm>
            ) : (<>
                    <SignUpForm
                        errors={alert?.errors}
                        loading={authLoading}
                        onSubmit={createAccount}
                    >
                    <SignUpPageHeader
                        title={'Start #gifting today'}
                        desc={'Create unique photos, videos, reviews, testimonials, and social buzz through the power of influencer gifting.'}
                    />

                </SignUpForm>
                </>
            )
        );
    }

    const repForm = () => {
        const representativeData = {
            name: representative?.name || '',
            mobile: representative?.mobile || '',
            email: representative?.auth.email
        };

        return (
            <RepresentativeProfileForm
                data={representativeData}
                headshotImage={representative?.headshot_image}
                errors={alert?.errors}
                loading={profileLoading}
                onSubmit={setRepresentativeProfile}
                onChangeImage={setRepresentativeProfileImage}
                signUpFlow={true}
            >
                <SignUpPageHeader
                    title={'Create your account'}
                />
            </RepresentativeProfileForm>
        );
    }

    const brandForm = () => {
        const brandData = {
            name: brand?.name || '',
            description: brand?.description || '',
            website: brand?.website || '',
            tags: brand?.tags || [],
            brand_type: brand?.brand_type || '',
            affiliate_code: brand?.affiliate_id || '',
        };

        const handleBrandSubmit = (form: BrandForm) => {
            void createBrandProfile(form);
        };

        return (
            <BrandProfileForm
                data={brandData}
                logo={brand?.logo}
                errors={alert?.errors}
                includeImage={Helpers.isEmpty(brand?.uid)}
                disabledAction={Helpers.isEmpty(brandData) ?
                    true : undefined}
                loading={profileLoading}
                onSubmit={handleBrandSubmit}  
                onChangeLogo={setBrandProfileLogo}
            >
                <SignUpPageHeader
                    title={'Your Brand'}
                    desc={'Please upload your logo'}
                />
            </BrandProfileForm>
        );
    }

    const brandSocials = () => {
        const socialProfileData = {
            social_profiles: brand?.social_profiles
        } as BrandForm;

        return (
            <SocialAccountsForm
                data={socialProfileData}
                errors={alert?.errors}
                loading={profileLoading}
                onSubmit={setBrandProfileSocial}
            >
                <SignUpPageHeader
                    title={'Social Accounts'}
                    desc={'Link one or more of your brands accounts'}
                />
            </SocialAccountsForm>
        );
    }

    const connectShopifyForm = () => {
        return (
            <ConnectShopifyForm
                loading={profileLoading}
                setShopifyProfile={setShopifyProfile}
            >
                <SignUpPageHeader title={'Connect your Shopify store'} desc={'Gift products directly from your Shopify store.'} extraDesc={'Orders are automatically created in your store when you select the Creator for gifting and they confirm options.'} />
            </ConnectShopifyForm>

        )
    }

    const inviteTeamForm = () => {
        return (
            <InviteTeamForm
                loading={profileLoading}
                onGiftFlow={() => finishInvite()}
            />
        )
    }


    const signupFlow = [
        signupForm,
        brandForm,
        brandSocials,
        repForm,
        // verifyForm,
        connectShopifyForm,
        inviteTeamForm
    ];

    const getSignupFlow = () => {
        return signupFlow;
    }

    const isCompleted = (nextStep: number) => {
        return getSignupFlow().length <= nextStep;
    }

    const renderForm = () => {
        const signupFlow = getSignupFlow();
        const stepRenderer = signupFlow[step];
        return stepRenderer ? stepRenderer() : null;
    }

    const setSignUpStep = (incDec: number) => {
        const nextStep = step + incDec;
        if (isCompleted(nextStep)) {
            completeSignUp();
        } else {
            if (nextStep < 1) {
                dispatcher(Actions.Auth.clearSignUp());
                analyticsEvents.onboardingExited({
                    email: signedUpEmail || '',
                });
                navigate('/', { replace: true });
            } else {
                dispatcher(Actions.Auth.setSignUpStep(nextStep));
            }
        }
    }

    if (!settings) {
        return (<div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Loader status={'loading'} />
        </div>);
    }

    return (
        <PublicLayout>
            <Container data-testid={'signup-page'}>
                {step > 0 && (
                    <GoBack
                        theme={'aqua'}
                        size={'medium'}
                        iconOnly={true}
                        onClick={() => setSignUpStep(-1)}
                    >
                        <AngleLeftIcon />
                    </GoBack>
                )}

                <Body>
                    {renderForm()}
                </Body>
            </Container>
        </PublicLayout>
    );
}
