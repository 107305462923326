import {useRef, useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';

import {Actions, useAppDispatch, useAppSelector} from 'store';
import {Hooks} from 'utils';
import {AppLogo, Button, Link, ProfileFrame} from 'app/components';
import {AngleDownIcon, AngleRightIcon, PlusCircleIcon} from 'app/icons';
import subscriptionService from "../../../../services/subscriptionService";
import UpgradeButton from "../../../components/UpgradeButton";
import { API } from 'api';

import { CarouselList } from 'app/components/CarouselList';
import AnalyticsEvents from "../../../../services/analyticsEvents";
import {ClientSettings} from "../../../../services/settingsService";

const StyledApplogo = styled(AppLogo)`
    cursor: pointer;
    height: 42px;
    width: 42px;
    margin-right: 18px;
    margin-left: 18px;


    path:first-of-type {
        display: block;
        height: 42px;
        width: 42px;
        transform: scale(4) translateY(-17px);    
    }


    path:nth-of-type(n+2) {
        display: none;
    }

    @media (max-width:900px) and (min-width:0px) {
        margin-left: 0px;
        margin-right: 0px;
    }
`;

const DropdownItem = styled.div`
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 24px;
    padding: 12px;
    border-radius: 14px;
    background-color: var(--grey-2);
    color: var(--grey-9);
    cursor: pointer;

    span {
        display: flex;
        align-items: center;
    }
`;

const Dropdown = styled.div<{
    $show?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    position: absolute;
    top: 110%;
    right: 0;
    width: 256px;
    padding: 10px 8px;
    border-radius: 12px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
    opacity: 0;
    transform: translate(-15px, -15px) scale(0);
    transform-origin: top right;
    transition: opacity 150ms linear, transform 150ms linear;
    z-index: var(--dropdown-index);

    ${(props) => props.$show && `
        opacity: 1;
        transform: translate(0, 9px) scale(1);
    `}

    @media (max-width:900px) and (min-width:0px) {
        left: -310%;
    }

`;

const ProfileDesc = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    row-gap: 1px;
    // margin-right: auto;

    span {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-overflow: ellipsis;
        word-break: break-word;
        white-space: nowrap;
        overflow: hidden;
        max-width: 113px;

    }

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const Profile = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 6px;
    position: relative;
    max-width: 256px;
    padding: 0px 8px;
    border-radius: 20px;
    background-color: var(--background);

    .profile-frame-headshot {
        margin: 3px 0px;
    }

    button {
        transform: scale(.8);
    }

    @media (max-width:900px) and (min-width:0px) {
        flex: 0 0 0px;
        width: auto;
        right: 0px;
        background: none;

        .profile-frame-headshot {
            display: none;
        }

        button {
            transform: scale(1);
        }
    }

`;

const Content = styled.div`
    display: flex;
    align-items: center;
    column-gap: 32px;
    margin-left: auto;

    @media (max-width:1227px) {

        .upgrade-btn {
            font-size: .9em;
        }
    }

    @media (max-width:1210px) {

        .upgrade-btn {
            font-size: .8em;

            svg {
                height: 16px;
                width: 16px;
            }
        }
    }

    @media (max-width:1181px) {

        .upgrade-btn {
            font-size: .8em;

            svg {
                display: none;
            }
        }
    }

    @media (max-width:1131px) {

        column-gap: 12px;

        .upgrade-btn {
            font-size: .7em;

            svg {
                display: none;
            }
        }
    }




    @media (max-width:900px) and (min-width:0px) {
        column-gap: 2px;
    }
`;

const Container = styled.header<{
    $loading?: boolean;
}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding-right: 20px;
    padding-left: 20px;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    background-color: var(--background-alt);
    box-shadow: 0px 0px 4px rgba(41, 43, 50, 0.25);
    position: relative;
    z-index: 100;


    ${(props) => props.$loading && `
        ${ProfileDesc} span {
            border-radius: 4px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            &:before {
                content: '###########';
                visibility: hidden;
            }
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    };


    @media (max-width:900px) and (min-width:0px) {
        position: fixed;
        width: 100vw;

        .create-gift-btn {
            width: 100px;
            font-size: 12px;

            svg {
                display: none;
            }
        }
    }
`;

const DisabledButtonDiv = styled.div`
    svg {
        path {
            fill: var(--grey-6);
        }
    }
`

const NavLinkBtn = styled.button`
    margin-left: 24px;
    color: var(--blue);
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;

    &.active {
        font-weight: 700;
    }

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`

type DrawerType = 'BLOG' | 'CASE_STUDIES';

const ArticlesDrawer = styled.div`
    position: absolute;
    top: 78px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 14px;
    padding-left: 40px;
    background: white;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
    width: 95vw;
    z-index: 100;
    border-radius: 20px;
    display: flex;
    align-items: center;
    animation: animateIn .15s linear 0s forwards;
    animation-iteration-count: 1;
    transform: scale(0);
    transform-origin: top;
    transition: all 150ms linear;

    &.blog {
        &::before {
            content: '';
            width: 0; 
            height: 0; 
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid white;    
            position: absolute;  
            top: -15px;
            left: 204px;
            transition: left 250ms ease-in-out;
            will-change: left;
        }    
    }

    &.case-studies {
        &::before {
            content: '';
            width: 0; 
            height: 0; 
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid white;    
            position: absolute;  
            top: -15px;
            left: 295px;
            transition: left 250ms ease-in-out;
            will-change: left;
        }
    }

    &::after {
        content: '';
        height: 100%;
        width: 147px;
        position: absolute;
        right: 20px;
        top: 0;
        background: linear-gradient(to left,rgba(255,255,255,1) 30%,rgba(0,0,0,0) 70%);
        pointer-events: none;
    }


`

const ArticleDrawerContent = styled.div`
    width: 230px;
    margin-right: 40px;
    margin-bottom: 22px;

    h1 {
        color: var(--blue);
        margin-bottom: 18px;
        margin-top: 0;
        padding: 0;
        font-weight: 600;
        font-size: 26px;
    }

    p {
        color: var(--blue);
        padding: 0;
        margin: 0;
    }
`

const CarouselContainer = styled.div`
    width: calc(95vw - 330px);
    overflow-x: hidden;
`

const ActiveOutOfOffice = styled.span`
    font-size: 14px;
    font-weight: bold;
    border-radius: 15px;
    color: var(--blue);
    background-color: var(--aqua);
    padding: 12px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    &:hover {
        opacity: 0.9;
        border: var(--blue) 1px solid;
    }
`;


export function PrivateLayoutHeader() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatcher = useAppDispatch();

    const {
        profile: {
            brand,
            representative,
            loading
        },
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const ref = useRef<HTMLDivElement>(null);

    const articleRef = useRef<HTMLDivElement>(null);
    const learnBtnRef = useRef<HTMLButtonElement>(null);
    const caseStudiesBtnRef = useRef<HTMLButtonElement>(null);


    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const analyticsEvents = useMemo(() => new AnalyticsEvents(settings as ClientSettings), [settings])

    Hooks.useOnClickOutside(ref, () => showDropdown &&
        setShowDropdown(false));

    const handleCreateGift = () => {
        const path = '/create';
        analyticsEvents.dashboardCreateGiftButtonClicked();
        if (location.pathname !== path) {
            navigate(path);
        }
    }

    const handleLogOut = () => {
        dispatcher(Actions.Auth.logout())
            .catch(() => {
                brand && analyticsEvents.logout(brand);
            })
            .catch((error) => {throw error})
    }

    const handleBillingNavigation = () => {
        if (settings?.features.subscriptions && (!brand?.subscription || !brand.subscription.status) ) {
            dispatcher(Actions.App.setShowPaymentModal(true, !subscriptionService.isSubscribed(brand?.subscription)));
            return
        }
        navigate('/subscription-billing')
    }

    const handleInvite = () => {
        navigate('/create/invite#creative#audience#deliverables#invite_creators')
    }

    const showButtons = useMemo(() => {
        if (location.pathname !== '/') {
            return false;
        }
        return true
    }, [location, navigate])

    const [displayArticleDrawer, setDisplayArticleDrawer] = useState<{show: boolean, type?: DrawerType}>({show: false, type: undefined})

    const [caseStudies, setCaseStudies] = useState([]);
    const [blogArticles, setBlogArticles] = useState([])

    useEffect(() => {
        if (!displayArticleDrawer.show) {
            return;
        }

        const fetchCaseStudies = async () => {
          try {
            const res: [] = await API.Shared.getCaseStudies();
            setCaseStudies(res);
          } catch (err) {
            throw new Error('An error occurred.');
          }
        };

        const fetchBlogArticles = async () => {
          try {
            const res: [] = await API.Shared.getBlogArticles();
            setBlogArticles(res);
          } catch (err) {
            throw new Error('An error occurred.');
          }
        };

        if (displayArticleDrawer.type === 'BLOG') {
            void fetchBlogArticles();
        }

        if (displayArticleDrawer.type === 'CASE_STUDIES') {
            void fetchCaseStudies();
        }

      }, [displayArticleDrawer]);

    const showArticles = (type?: DrawerType) => {
        if (displayArticleDrawer.show && displayArticleDrawer.type === type) {
            setDisplayArticleDrawer({show: false, type: undefined});
        } else {
            setDisplayArticleDrawer({show: true, type: type});
        }
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {

          if ((learnBtnRef.current && learnBtnRef.current.contains(event.target as Node) && displayArticleDrawer.type ==='BLOG')) {
              return
          }

          if ((caseStudiesBtnRef.current && caseStudiesBtnRef.current.contains(event.target as Node) && displayArticleDrawer.type ==='CASE_STUDIES')) {
            return
          }


          if ((learnBtnRef.current && (!learnBtnRef.current.contains(event.target as Node))) && (caseStudiesBtnRef.current && !caseStudiesBtnRef.current.contains(event.target as Node)) && (articleRef.current && !articleRef.current.contains(event.target as Node))) {
            return setDisplayArticleDrawer({show: false, type: undefined});
          }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [learnBtnRef, caseStudiesBtnRef, articleRef, displayArticleDrawer]);

      useEffect(() => {
        if (
            displayArticleDrawer.show &&
            displayArticleDrawer.type === 'CASE_STUDIES'
        ) {
            analyticsEvents.dashboardCaseStudyOpened();
        }

        if (displayArticleDrawer.show && displayArticleDrawer.type === 'BLOG') {
            analyticsEvents.dashboardBlogLearnOpened();
        }
    }, [displayArticleDrawer]);      

    return (
        <>
            <Container
                data-testid={'private-layout-header'}
                $loading={!representative && loading}
            >

                <StyledApplogo onClick={() => navigate('/')} />

                {showButtons ? (
                    brand?.status === 'PENDING' ? (
                        <DisabledButtonDiv>
                            <Button
                                disabled
                                theme={'aqua'}
                                size={'small'}
                            >
                                <PlusCircleIcon />
                                <span>Create Gift</span>
                            </Button>

                        </DisabledButtonDiv>
                    ) : (
                        <div>
                            <Button
                                className='create-gift-btn'
                                theme={'aqua'}
                                size={'small'}
                                onClick={handleCreateGift}
                            >
                                <PlusCircleIcon />
                                <span>Create Gift</span>
                            </Button>
                        </div>
                    )

                ) : null}

                {showButtons && (
                    <>
                        <NavLinkBtn ref={learnBtnRef} className={displayArticleDrawer.type ==='BLOG' ? 'active' : ''} onClick={() => showArticles('BLOG')}>Learn</NavLinkBtn>

                        <NavLinkBtn ref={caseStudiesBtnRef} className={displayArticleDrawer.type ==='CASE_STUDIES' ? 'active' : ''} onClick={() => showArticles('CASE_STUDIES')}>Case studies</NavLinkBtn>
                    </>
                )}

                {brand?.active_out_of_office && (
                    <Link to={'/notifications'} style={{
                        marginBottom: "50px"
                    }} >
                        <ActiveOutOfOffice>
                                You are currently out of office
                        </ActiveOutOfOffice>
                    </Link>

                )}

                <Content>
                    {brand && (<UpgradeButton brand={brand}/>)}
                    {/*<Notifications*/}
                    {/*    items={notifications}*/}
                    {/*    onItemClick={handleNotificationItemClick}*/}
                    {/*/>*/}

                    <Profile>
                        <ProfileFrame
                            className="profile-frame-headshot"
                            id={'headshotImage'}
                            key={representative?.uid}
                            width={40}
                            height={40}
                            image={representative?.headshot_image}
                            loading={!representative && loading}
                        >
                        </ProfileFrame>

                        <ProfileDesc>
                            <span>{representative?.name}</span>
                        </ProfileDesc>

                        <div ref={ref}>
                            <Button
                                theme={'aqua'}
                                size={'small'}
                                iconOnly={true}
                                onClick={() => {
                                    analyticsEvents.settingsButtonOpened();
                                    setShowDropdown((prev) => !prev)
                                }}
                            >
                                <AngleDownIcon/>
                            </Button>

                            <Dropdown $show={showDropdown}>
                                <DropdownItem onClick={() => navigate('/brand-profile')}>
                                    <span>My Brand</span>

                                    <span>
                                    <AngleRightIcon/>
                                </span>
                                </DropdownItem>

                                <DropdownItem onClick={() => navigate('/representative-profile')}>
                                    <span>My Profile</span>

                                    <span>
                                        <AngleRightIcon/>
                                    </span>
                                </DropdownItem>

                                <DropdownItem onClick={() => navigate('/teams')}>
                                    <span>Manage Team</span>

                                    <span>
                                        <AngleRightIcon/>
                                    </span>
                                </DropdownItem>

                                <DropdownItem onClick={() => navigate('/brand-reviews')}>
                                    <span>Brand Reviews</span>

                                    <span>
                                        <AngleRightIcon/>
                                    </span>
                                </DropdownItem>

                                {settings?.features.subscriptions && (
                                    <DropdownItem onClick={() => {
                                        analyticsEvents.settingsBillingOpened();
                                        handleBillingNavigation()
                                    }}>
                                        <span>Subscription &amp; Billing</span>

                                        <span>
                                    <AngleRightIcon/>
                                </span>
                                    </DropdownItem>
                                )}

                                {(settings?.features.shopify || settings?.features.yotpo) && (
                                    <DropdownItem onClick={() => navigate('/integrations')}>
                                        <span>Integrations</span>

                                        <span>
                                    <AngleRightIcon/>
                                </span>
                                    </DropdownItem>
                                )}

                                <DropdownItem onClick={handleInvite}>
                                    <span>Invite Creators</span>

                                    <span>
                                        <AngleRightIcon/>
                                    </span>
                                </DropdownItem>


                                <DropdownItem onClick={() => navigate('/notifications')}>
                                    <span>Notifications</span>

                                    <span>
                                    <AngleRightIcon/>
                                </span>
                                </DropdownItem>


                                <DropdownItem onClick={() => {
                                    analyticsEvents.settingsHelpOpened();
                                    window.open('https://www.hashgifted.com/help')
                                }}>
                                    <span>Help</span>

                                    <span>
                                    <AngleRightIcon/>
                                </span>
                                </DropdownItem>

                                <DropdownItem onClick={handleLogOut}>
                                    <span>Log Out</span>

                                    <span>
                                    <AngleRightIcon/>
                                </span>
                                </DropdownItem>
                            </Dropdown>
                        </div>
                    </Profile>
                </Content>
            </Container>

            {
                displayArticleDrawer.show && (
                    <ArticlesDrawer className={displayArticleDrawer.type ==='BLOG' ? 'blog' : 'case-studies'} ref={articleRef}>
                        <ArticleDrawerContent>
                            <h1>{displayArticleDrawer.type === 'BLOG' ? 'Learn' : 'Case Studies'}</h1>
                            <p>{displayArticleDrawer.type === 'BLOG' ? 'Learn how to create amazing creator campaigns with articles by the #gifted team' : 'Read about how the best brands in every category have used #gifted for creating unique UGC'}</p>
                        </ArticleDrawerContent>

                        <CarouselContainer>
                            <CarouselList list={displayArticleDrawer.type === 'BLOG' ? blogArticles : caseStudies} />
                        </CarouselContainer>
                    </ArticlesDrawer>
                )
            }
        </>
    );
}
