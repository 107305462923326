import {SubscriptionFlowModal} from "../SubcriptionFlowModal";
import {Actions, useAppDispatch, useAppSelector} from "../../../store";
import {useMemo} from "react";
import Modal from "../Modal";
import subscriptionService from "../../../services/subscriptionService";
import { Helpers } from "utils";


const PaymentRequiredHandler = () => {

    const {
        global: {
            showPaymentModal,
            showPaymentDialog,
            subscriptions,
            settings
        },
        profile: {
            brand
        }
    } = useAppSelector((state) => state);

    const dispatcher = useAppDispatch();

    const creatorLimitDescription = (brand: BrandProfile, subscriptions: Subscription[]) => {
        if (!brand.subscription || !brand.subscription.subscription) {
            return;
        }

        const currentPlan = subscriptionService.findPlanTypeForSubscription(brand.subscription);
        const nextPlan = subscriptionService.findUpgradePlan(currentPlan);
        if (!nextPlan) {
            return;
        }
        const nextPlanDefinition = subscriptionService.findPlanDefinition(nextPlan);
        const nextSubscription = subscriptionService.findSubscription(subscriptions, nextPlan, 'MONTH');
        const features = subscriptionService.features(nextSubscription);
        const numCreators = features.get('influencers.accept.limit') || 'unlimited';
        return `Upgrade to ${nextPlanDefinition.name} and book ${numCreators} creators per gift`;
    };

    const giftLimitDescription = (brand: BrandProfile, subscriptions: Subscription[]) => {
        if (!brand.subscription || !brand.subscription.subscription) {
            return;
        }
        const currentPlan = subscriptionService.findPlanTypeForSubscription(brand.subscription);
        const nextPlan = subscriptionService.findUpgradePlan(currentPlan);
        if (!nextPlan) {
            return;
        }
        const nextPlanDefinition = subscriptionService.findPlanDefinition(nextPlan);
        const nextSubscription = subscriptionService.findSubscription(subscriptions, nextPlan, 'MONTH');
        const features = subscriptionService.features(nextSubscription);
        const numGifts = features.get('gifts.create.limit') || 'unlimited';
        return `You can submit another gift on ${Helpers.formatDate(brand.subscription?.expires_at || '', 'MMMM dd, Y')}\nUpgrade to ${nextPlanDefinition.name} and create ${numGifts} gifts per month`;
    };

    const title = useMemo(() => {

        switch (showPaymentDialog.type) {
            case 'subscription_required':
                return `Please choose a subscription`;
            case 'subscription_required_trial':
                return `Please start a trial or choose a subscription`;
            case 'limit_creator_accepted':
                return `You have your accepted creator limit`;
            case 'limit_gifts_created':
                return `You have reached your gift limit`
            case 'trial_limit_creator_accepted':
                return `You’ve reached your trials accepted creator limit`;
            case 'trial_limit_gift_submitted_starter':
                return `You’ve reached the starter gift limit`;
            case 'trial_limit_gift_submitted_grow_pro':
                return `You’ve reached the free trial limit`;
            case 'trial_limit_shortlist':
                return `You’ve reached your trials shortlisted creator limit`;
        }

    }, [showPaymentDialog, brand]);

    const description = useMemo(() => {
        if (!brand) {
            return;
        }
        switch (showPaymentDialog.type) {
            case 'subscription_required':
                return `You can create so much with a #gifted subscription. With access to over 5,000 creators you can create amazing content for your brand easily. \n\n No contract, no lock-ins. Pay month to month or save 16% with a yearly subscription.\n\nIf you exit, your gift will be saved as a draft. `;
            case 'subscription_required_trial':
                return `You can create so much with a #gifted subscription. With access to over 5,000 creators you can create amazing content for your brand easily. \n\n No contract, no lock-ins. Start a free trial today then pay month to month or save 16% with a yearly subscription.\n\nIf you exit, your gift will be saved as a draft. `;            case 'limit_creator_accepted':
                return creatorLimitDescription(brand, subscriptions);
            case 'limit_gifts_created':
                return giftLimitDescription(brand, subscriptions);
            case 'trial_limit_creator_accepted':
                return `You’ve reached the trial accepted creator limit`;
            case 'trial_limit_gift_submitted_starter':
                return `To post more than 1 gift a month, you'll need a GROW or PRO subscription`;
            case 'trial_limit_gift_submitted_grow_pro':
                return `Subscribe now to post more gifts`;
            case 'trial_limit_shortlist':
                return `To shortlist more than 5 creators, subscribe now`;
        }
    }, [showPaymentDialog, brand]);

    const image = useMemo(() => {
        if (!brand) {
            return;
        }
        switch (showPaymentDialog.type) {
            case 'subscription_required':
                return 'star';
            case 'subscription_required_trial':
                return 'star';
            case 'limit_creator_accepted':
                return 'megaphone';
            case 'trial_limit_creator_accepted':
                return 'megaphone';
            case 'limit_gifts_created':
                return 'gift';
            case 'trial_limit_gift_submitted_starter':
                return 'gift';
            case 'trial_limit_gift_submitted_grow_pro':
                return 'gift';
            case 'trial_limit_shortlist':
                return 'megaphone';
        }
    }, [showPaymentDialog, brand]);

    const primaryAction = ()=> {
        void dispatcher(Actions.App.setShowPaymentDialog(false));
        void dispatcher(Actions.App.setShowPaymentModal(true, true));
    };
    const secondaryAction = ()=> {
        void dispatcher(Actions.App.setShowPaymentDialog(false));
    };

    return (
        <>
            {settings?.features.subscriptions && (
                showPaymentModal.show  && (
                    <SubscriptionFlowModal showAllPlans={showPaymentModal.showAllPlans} />
                )
            )}
            {settings?.features.subscriptions && (
                showPaymentDialog.show && title && description && image && (
                    <Modal show={true} title={title} description={description} image={image} size={'medium'} primaryAction={{
                        name: showPaymentDialog.type === 'subscription_required_trial' ? 'Start a trial' : 'See pricing',
                        action() {
                            primaryAction();
                        },
                        theme: 'alert'
                    }} secondaryAction={{
                        name: 'Maybe later',
                        action() {
                            secondaryAction();
                        }
                    }}/>
                )
            )}
        </>
    );
}

export default PaymentRequiredHandler;
