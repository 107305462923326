import { batch } from 'react-redux';

import { API } from 'api';
import { Converter } from 'utils';
import { Constants } from '../constants';
import settingsService from "../../services/settingsService";

const _setLoading = (loading: boolean) => ({
    type: Constants.Global.Loading,
    payload: loading
});

const getStates = (id: number) => {
    return async (dispatch: AppDispatch) => {
        if (id) {
            dispatch(_setLoading(true));

            try {
                const result = await API.Shared.getStates(id);

                batch(() => {
                    dispatch({
                        type: Constants.Global.States,
                        payload: result
                    });

                    dispatch(_setLoading(false));
                });
            } catch {
                dispatch(_setLoading(false));
            }
        } else {
            dispatch({
                type: Constants.Global.States,
                payload: []
            });
        }
    }
}

const getPrivateSharedInfo = (brand: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(_setLoading(true));

        try {
            const settings = await settingsService.loadSettings(brand);
            batch(() => {
                dispatch({
                    type: Constants.Global.Settings,
                    payload: settings
                });

                dispatch({
                    type: Constants.Global.Attributes,
                    payload: settings.attributes
                });

                dispatch({
                    type: Constants.Global.Deliverables,
                    payload: settings.deliverables
                });

                dispatch({
                    type: Constants.Global.Tags,
                    payload: settings.tags
                });

                dispatch({
                    type: Constants.Global.Subscriptions,
                    payload: settings.subscriptions
                });

                dispatch({
                    type: Constants.Global.GiftStatus,
                    payload: Converter.toIdName(settings.gift_statuses)
                });

                dispatch({
                    type: Constants.Global.GiftTypes,
                    payload: Converter.toIdName(settings.gift_types)
                });

                dispatch(_setLoading(false));
            });
        } catch {
            dispatch(_setLoading(false));
        }
    }
}

const getPublicSharedInfo = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(_setLoading(true));

        try {
            const [
                subscriptions
            ] = await Promise.all([
                API.Shared.getSubscriptions(),
            ]);

            batch(() => {

                dispatch({
                    type: Constants.Global.Subscriptions,
                    payload: subscriptions
                });

                dispatch(_setLoading(false));
            });
        } catch {
            dispatch(_setLoading(false));
        }
    }
}

const setAlert = (alert: ErrorResponse | null) => {
    return (dispatch: AppDispatch) => {
        dispatch({
            type: Constants.Global.Alert,
            payload: alert
        });
    }
}

const setInputError = (error: any) => {
    return (dispatch: AppDispatch) => {
        dispatch({
            type: Constants.Global.InputError,
            payload: error
        });
    }
}

const setBooting = (booting: boolean) => {
    return (dispatch: AppDispatch) => {
        dispatch({
            type: Constants.Global.Booting,
            payload: booting
        });
    }
}

const setShowPaymentModal = (show: boolean, showAllPlans = false) => {

    return (dispatch: AppDispatch) => {
        dispatch({
            type: Constants.Global.ShowPaymentModal,
            payload: {show: show, showAllPlans: showAllPlans}
        });
    }
}

const setShowPaymentDialog = (show: boolean, type?: RequiresPaymentResponseCode) => {
    return (dispatch: AppDispatch) => {
        dispatch({
            type: Constants.Global.ShowPaymentDialog,
            payload: {show: show, type: type}
        });
    }
}

export const appActions = {
    getStates,
    getPublicSharedInfo,
    getPrivateSharedInfo,
    setAlert,
    setInputError,
    setBooting,
    setShowPaymentModal,
    setShowPaymentDialog
};
