import {ReactNode, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';

import {Hooks} from 'utils';
import {CloseIcon, StarFilledIcon, StarIcon} from 'app/icons';
import {Button} from '../Button';
import {FormTextarea} from '../FormTextarea';
import { FormRadio } from '../FormRadio';

interface Props {
    influencer?: Influencer;
    completed?: boolean;
    show?: boolean;
    loading?: boolean;
    onClose(): void;
    onSubmit(form: ReviewForm): void;
}

const StyledButton = styled(Button)`
    align-self: flex-end;
`;

const Image = styled.img`
    width: 220px;
    height: 220px;
`;

const Title = styled.span`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.75px;
    color: var(--blye);
`;

const Desc = styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.25px;
    color: var(--grey-6);
    white-space: break-spaces;
`;

const Star = styled.span`
    padding: 0 3px;
    transform: scale(1);
    transition: transform 300ms ease;
    cursor: pointer;

    &:active {
        transform: scale(0.75);
    }
`;

const Rating = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -3px;
    margin-left: -3px;

    svg path {
        fill: var(--yellow);
    }
`;

const Header = styled.div<{
    $completed?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    ${(props) => props.$completed && `
        ${Image} {
            margin: 0 auto;
        }

        ${Title} {
            font-size: 40px;
            font-weight: 300;
            line-height: 48px;
            margin-top: -20px;
            margin-bottom: 20px;
            letter-spacing: -1.25px;
            color: var(--grey-9);
        }
    `}
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 28px;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 80px;
`;

const Content = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
    width: 439px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: var(--modal-index);

    button {
        margin-top: auto;
    }
`;

const initialForm = {
    rating: 0,
    review: '',
    feedback: '',
    anonymous: false
};

export function ReviewModal({
    influencer,
    completed,
    show,
    loading,
    onClose,
    onSubmit
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);

    const [form, setForm] = useState<ReviewForm>(initialForm);

    const [hover, setHover] = useState<number>(form.rating);

    const disabledSubmit = useMemo(() => {
        return !form.review ||
            form.rating === 0;
    }, [form]);

    Hooks.useOnClickOutside(contentRef, () => show && onClose());

    const handleSubmit = () => {
        onSubmit(form);
    }

    const handleChange = (
        name: string,
        value: string | number
    ) => {
        setForm((prev) => ({
            ...prev,
            [name]: value
        }));

        if (name === 'rating') {
            setHover(value as number);
        }

        if (name === 'anonymous') {
            setForm((prev) => ({
                ...prev,
                anonymous: !form.anonymous
            }));
        }
    }

    const handleExitComplete = () => {
        setForm(initialForm);
        setHover(0);
    }

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    const handleButtonClick = () => {
        if (completed) {
            onClose();
        } else {
            handleSubmit();
        }
    }

    return renderToPortal(
        <AnimatePresence onExitComplete={handleExitComplete}>
            {show && (
                <Container
                    {...containerAnimation}
                    data-testid={'review-modal-component'}
                >
                    <Content
                        {...contentAnimation}
                        ref={contentRef}
                    >
                        <Header $completed={completed}>
                            <StyledButton
                                theme={'aqua'}
                                size={'small'}
                                iconOnly={true}
                                onClick={onClose}
                            >
                                <CloseIcon />
                            </StyledButton>

                            {completed ?
                                (
                                    <>

                                        <Image
                                            src={require('../../../images/star.png')}
                                            alt={`Review submitted`}
                                        />

                                        <Title>
                                            Review Submitted
                                        </Title>

                                        <Desc>
                                            Thanks for your feedback. <br />
                                            Your review has been successfully <br />
                                            submitted.
                                        </Desc>
                                    </>
                                )
                                : (
                                    <>
                                        <Title>
                                            How was your experience with {influencer?.name}
                                        </Title>

                                        <Rating>
                                            {
                                                Array(5)
                                                    .fill('')
                                                    .map((_, i) => (
                                                        <Star
                                                            key={i}
                                                            onClick={() => handleChange('rating', i + 1)}
                                                            onMouseEnter={() => setHover(i + 1)}
                                                            onMouseLeave={() => setHover(form.rating)}
                                                        >
                                                            {(hover < i + 1) ? <StarIcon /> : <StarFilledIcon />}
                                                        </Star>
                                                    ))
                                            }
                                        </Rating>
                                    </>
                                )
                            }

                        </Header>

                        {!completed && (
                            <Body>
                                <FormTextarea
                                    name={'review'}
                                    label={'Public Review'}
                                    placeholder={'Type public review for influencer...'}
                                    value={form.review}
                                    required={true}
                                    limit={500}
                                    onChange={handleChange}
                                />
                            </Body>
                        )}

                        {!completed && (
                            <div 
                                onClick={(e) => handleChange('anonymous', `${form.anonymous}`)}
                                style={{display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer'}}
                            >
                                <label style={{cursor: 'pointer', fontSize: '16px', fontWeight: 300, display: 'flex', alignItems: 'center', lineHeight: '24px', color: 'var(--grey-9)'}}>
                                    Make review anonymous
                                </label>
                                <FormRadio
                                    checked={form.anonymous}
                                />
                            </div>
                        )}

                        <Actions>
                            <Button
                                disabled={!completed && disabledSubmit}
                                loading={loading}
                                onClick={handleButtonClick}
                            >
                                {completed ? 'Close' : 'Submit review'}
                            </Button>
                        </Actions>
                    </Content>
                </Container>
            )}
        </AnimatePresence>
    );
}
